import { defineMessages } from 'react-intl';
import {
  LOAN_PURPOSE_RESIDENTIAL,
  LOAN_PURPOSE_INVESTMENT,
  LOAN_PURPOSE_REFINANCE,
} from 'shared/constants/loanPurposes';
import {
  BUYER_SCENARIOS_PATH,
  ABOUT_PROPERTY_PATH,
  REASON_FOR_REFINANCE_PATH,
} from 'shared/constants/paths';
import House from '../../assets/buttons/lm-icon-house.png';
import Collect from '../../assets/buttons/lm-icon-collect.png';
import Breakdown from '../../assets/buttons/lm-icon-breakdown.png';

export const messages = defineMessages({
  compareTitle: {
    id: 'loanMarket.propertyGoals.compareTitle',
    defaultMessage: 'Spoilt for choice.',
  },
  compareButtonText: {
    id: 'loanMarket.propertyGoals.compareButtonText',
    defaultMessage: 'Compare loans',
  },
  negotiateTitle: {
    id: 'loanMarket.propertyGoals.negotiateTitle',
    defaultMessage: 'Negotiate.',
  },
  negotiateDescription: {
    id: 'loanMarket.propertyGoals.negotiateDescription',
    defaultMessage:
      'We’ve got your back. We work with lenders day in, day out, so we understand their policies, criteria and turnaround times. We work in your best interest to find the right loan for you and play hardball on your behalf to ensure you get a competitive deal that helps you achieve your goals sooner.',
  },
  negotiateButtonText: {
    id: 'loanMarket.propertyGoals.negotiateButtonText',
    defaultMessage: 'Get the ball rolling',
  },
  saveTitle: {
    id: 'loanMarket.propertyGoals.saveTitle',
    defaultMessage: 'Plain and simple savings.',
  },
  saveDescription: {
    id: 'loanMarket.propertyGoals.saveDescription',
    defaultMessage:
      'We’re experts so you don’t have to be. We will recommend loan structures that suit your situation and can show you ways you could save in interest',
  },
  saveButtonText: {
    id: 'loanMarket.propertyGoals.saveButtonText',
    defaultMessage: 'Find your loan',
  },
  ratingTitle: {
    id: 'loanMarket.propertyGoals.ratingTitle',
    defaultMessage: 'Over 1,000 5-star Google reviews',
  },
  ratingDescription: {
    id: 'loanMarket.propertyGoals.ratingDescription',
    defaultMessage:
      'We come highly recommended and are home to the highest-rate brokers.',
  },
  getStartedButtonText: {
    id: 'loanMarket.propertyGoals.getStartedButtonText',
    defaultMessage: 'Let’s get started',
  },
});

const loanPurposeMessages = defineMessages({
  residentialLabel: {
    id: 'loanMarket.loanPurposes.residential.label',
    defaultMessage: 'Buy a home or move',
  },
  investmentLabel: {
    id: 'loanMarket.loanPurposes.investment.label',
    defaultMessage: 'Buy an investment property',
  },
  refinanceLabel: {
    id: 'loanMarket.loanPurposes.refinance.label',
    defaultMessage: 'Refinance or renovate',
  },
});

export const loanPurposes = [
  {
    key: LOAN_PURPOSE_RESIDENTIAL,
    value: LOAN_PURPOSE_RESIDENTIAL,
    icon: House,
    url: `${BUYER_SCENARIOS_PATH}?loan_purpose=${LOAN_PURPOSE_RESIDENTIAL}`,
    label: loanPurposeMessages.residentialLabel,
  },
  {
    key: LOAN_PURPOSE_INVESTMENT,
    value: LOAN_PURPOSE_INVESTMENT,
    icon: Collect,
    url: ABOUT_PROPERTY_PATH,
    label: loanPurposeMessages.investmentLabel,
  },
  {
    key: LOAN_PURPOSE_REFINANCE,
    value: LOAN_PURPOSE_REFINANCE,
    icon: Breakdown,
    label: loanPurposeMessages.refinanceLabel,
    url: `${REASON_FOR_REFINANCE_PATH}?loan_purpose=${LOAN_PURPOSE_REFINANCE}`,
  },
];
