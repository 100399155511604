/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from '@loan_market/react-router-redux-multi';
import { bindActionCreators, compose } from 'redux';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import {
  STAMP_DUTY_PATH,
  BANKING_PREFERENCES_PATH,
  LOAN_REPORT_PATH,
} from 'shared/constants/paths';
import {
  REQUIRED,
  BUTTON_REQUIRED,
  LOCALITY,
  LESS_THAN_PROPERTY_VALUE,
  GREATER_THAN_ZERO,
  AT_LEAST_TWENTY_THOUSAND,
  NO_ERROR,
} from 'constants/validators';
import {
  YES_NO_OPTIONS,
  STATE_OPTIONS,
  PERCENTAGE_OPTIONS,
} from 'constants/options';
import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';
import { isResidential, isInvestment } from 'shared/lib/checkLoanPurpose';
import {
  getProspectiveProperty,
  getDefaultExistingProperty,
} from 'shared/lib/propertyHelper';
import { aboutPropertyTitleCopy } from 'lib/copyHelper';
import Questions from 'lib/Questions';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import sanityCheck from 'hocs/sanityCheck';

import findLocality from 'services/localitiesApi';
import { intlShape, injectIntl } from 'react-intl';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Selection from 'components/Selection/Selection';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import PercentageDropdown from 'components/PercentageDropdown/PercentageDropdown';
import locale from 'config/locale';

import { formatCurrency } from 'lib/intlFormatters';
import generatePurchaseCostParams from 'lib/purchaseCostParamsBuilder';

const propsTransformForQuestion = (props) => ({
  ...getProspectiveProperty(props.scenario),
  loanPurpose: props.scenario.loanPurpose,
  foundProperty: props.scenario.foundProperty,
  depositSaved: props.scenario.depositSaved,
});

const questionSet = (scenario) => {
  const questions = new Questions();
  questions.addBranch(['foundProperty', BUTTON_REQUIRED]);
  if (
    locale.isAU &&
    scenario.foundProperty === false &&
    (isResidential(scenario) || isInvestment(scenario))
  ) {
    questions.addBranch(['state', REQUIRED]);
  }
  if (scenario.foundProperty === true) {
    questions.addBranch(['locality', LOCALITY]);
  }
  if (scenario.foundProperty !== undefined) {
    questions.add(
      ['value', REQUIRED, AT_LEAST_TWENTY_THOUSAND],
      ['depositSaved', BUTTON_REQUIRED],
    );
  }
  if (scenario.depositSaved) {
    questions.addBranch([
      'depositAmount',
      REQUIRED,
      LESS_THAN_PROPERTY_VALUE,
      GREATER_THAN_ZERO,
    ]);
  }
  questions.addBranch('linkButton');
  return questions.arrayOfQuestions();
};

class AboutProperty extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    scenario: PropTypes.object.isRequired,
    setFoundProperty: PropTypes.func.isRequired,
    setDepositSaved: PropTypes.func.isRequired,
    setPropertyState: PropTypes.func.isRequired,
    setPropertyLocality: PropTypes.func.isRequired,
    setPropertyValue: PropTypes.func.isRequired,
    setPropertyDepositAmount: PropTypes.func.isRequired,
    setPropertyRentalAmount: PropTypes.func.isRequired,
    setPropertyRentalFrequency: PropTypes.func.isRequired,
    setPropertyError: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;

  static revealMethod = 'steps';
  static revealOverBranch = false;

  static isRequiredStructure = (props) =>
    !!getProspectiveProperty(props.scenario);

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  nextPath() {
    const { scenario } = this.props;
    return isResidential(scenario) || isInvestment(scenario)
      ? STAMP_DUTY_PATH
      : BANKING_PREFERENCES_PATH;
  }

  handleSubmit = () => {
    const {
      scenario,
      mergePropertyReportData,
      startAnimation,
      setNextPath,
      push: goTo,
    } = this.props;
    if (locale.isNZ) {
      mergePropertyReportData(generatePurchaseCostParams(scenario));
      setNextPath(LOAN_REPORT_PATH);
      startAnimation(['/calculator-nz']);
    } else {
      const nextPath = this.nextPath();
      goTo(nextPath);
    }
  };

  setPropertyDetails = (suburb) => {
    const { setPropertyLocality, setPropertyState } = this.props;
    setPropertyLocality(suburb);
    setPropertyState(suburb.state);
  };

  render() {
    const {
      intl,
      scenario,
      formCompleted,
      questions,
      setCurrentQuestionTo,
      setFoundProperty,
      setDepositSaved,
      setPropertyState,
      setPropertyValue,
      setPropertyDepositAmount,
      setPropertyError,
    } = this.props;
    const { foundProperty, depositSaved } = scenario;
    const prospectiveProperty = getProspectiveProperty(scenario);

    const existingProperty = getDefaultExistingProperty(scenario);
    const hasEquity =
      existingProperty &&
      existingProperty.intendsToSell &&
      existingProperty.equity > 0;
    const depositLabel = hasEquity
      ? `Will you be making a deposit?<br>(Keep in mind, you will have around ${formatCurrency(
          intl,
        )(existingProperty.equity)} after selling your home)`
      : 'Do you have money saved for a deposit?';
    return (
      <View>
        <ContentsWrapper
          id='aboutProperty'
          title={aboutPropertyTitleCopy(scenario)}
          isForm
          formCompleted={formCompleted}
          onSubmit={this.handleSubmit}
          onFocusLost={setCurrentQuestionTo(undefined)}
        >
          <Question
            {...questions.foundProperty}
            label='Have you found the property you want to buy?'
            direction='row'
          >
            <RadioButtonList
              items={YES_NO_OPTIONS}
              action={setFoundProperty}
              value={foundProperty}
            />
          </Question>

          <Question
            {...questions.state}
            label='Which state will you be purchasing in?'
          >
            <Selection
              action={setPropertyState}
              value={prospectiveProperty.state || ''}
              items={STATE_OPTIONS}
            />
          </Question>

          <Question
            {...questions.locality}
            label='What suburb is the property located in?'
            hint={locale.isAU ? 'Enter a Suburb or Postcode' : 'Enter a Suburb'}
          >
            <AutocompleteInput
              setAsyncError={(text) =>
                setPropertyError({ id: 'locality', text, blocking: true })
              }
              removeAsyncError={() =>
                setPropertyError({ id: 'locality', text: NO_ERROR })
              }
              action={this.setPropertyDetails}
              value={prospectiveProperty.locality}
              serviceFn={findLocality}
            />
          </Question>

          <Question
            {...questions.value}
            label={
              foundProperty
                ? 'How much do you expect the property to cost?'
                : 'About how much are you looking to spend?'
            }
          >
            <CurrencyInput
              action={setPropertyValue}
              value={prospectiveProperty.value}
            />
          </Question>

          <Question
            {...questions.depositSaved}
            label={depositLabel}
            direction='row'
          >
            <RadioButtonList
              items={YES_NO_OPTIONS}
              action={setDepositSaved}
              value={depositSaved}
            />
          </Question>

          <Question
            {...questions.depositAmount}
            label={
              hasEquity
                ? 'How much will your deposit be?'
                : 'How much do you have saved for a deposit?'
            }
          >
            <PercentageDropdown
              action={setPropertyDepositAmount}
              value={prospectiveProperty.depositAmount}
              valueToPercentage={(value) => value / prospectiveProperty.value}
              percentageToValue={(percentage) =>
                Math.round(prospectiveProperty.value * percentage)
              }
              percentageList={PERCENTAGE_OPTIONS}
            />
          </Question>

          <ButtonNext
            id='next'
            {...questions.linkButton}
            disabled={!formCompleted}
            onClick={this.handleSubmit}
          />
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFoundProperty: scenarioActions.setFoundProperty,
      setDepositSaved: scenarioActions.setDepositSaved,
      setPropertyState: scenarioActions.setPropertyState(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyLocality: scenarioActions.setPropertyLocality(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyValue: scenarioActions.setPropertyValue(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyDepositAmount: scenarioActions.setPropertyDepositAmount(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyRentalAmount: scenarioActions.setPropertyRentalAmount(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyRentalFrequency: scenarioActions.setPropertyRentalFrequency(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyError: scenarioActions.setPropertyError(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
      mergePropertyReportData: scenarioActions.mergePropertyReportData(
        PROSPECTIVE_PROPERTY_ID,
      ),
      push,
    },
    dispatch,
  );

export default injectIntl(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    sanityCheck,
    manageQuestions,
  )(AboutProperty, propsTransformForQuestion),
);
