import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import UIActions from 'actions/UIActions';
import DataCollectionVerifyId from 'components/DataCollectionVerifyId/DataCollectionVerifyId';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import { injectIntl } from 'react-intl';

const DataCollectionIDVerification = ({
  isSpinnerLoading,
  pushSpinner,
  popSpinner,
  applicationId,
  setPageError,
  selectedProcesses,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View showMenu={false} showHelpFooter>
      <Spinner loading={isSpinnerLoading}>
        <DataCollectionVerifyId
          pushSpinner={pushSpinner}
          popSpinner={popSpinner}
          applicationId={applicationId}
          setPageError={setPageError}
          selectedProcesses={selectedProcesses}
        />
      </Spinner>
    </View>
  );
};

DataCollectionIDVerification.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  pushSpinner: PropTypes.func,
  popSpinner: PropTypes.func,
  applicationId: PropTypes.number,
  setPageError: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  applicationId: applicationSelectors.getApplicationId(state),
  selectedProcesses: dataCollectionSelectors.selectedProcesses(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
      setPageError: UIActions.setPageError,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataCollectionIDVerification),
);
