import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import locale from 'config/locale';

import LoanMarketPropertyGoals from './loan-market/LoanMarketPropertyGoals';
import PropertyGoals from './PropertyGoals';

export class LandingPage extends Component {
  static propTypes = { advisorOrg: PropTypes.object };
  static defaultProps = { advisorOrg: {} };

  render() {
    const Component =
      locale.isAU && !this.props.advisorOrg.isOwnBrand
        ? LoanMarketPropertyGoals
        : PropertyGoals;

    return <Component {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  advisorOrg: state.advisorOrg,
});

export default injectIntl(connect(mapStateToProps)(LandingPage));
