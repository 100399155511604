import React from 'react';
import styles from './DataCollectionPageSection.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';

export const DataCollectionPageSection = ({
  className,
  children,
  id,
  inverse,
  fullWidth,
}) => {
  return (
    <ContentsWrapper
      id={id}
      className={classNames({
        [styles.whitePage]: !inverse,
        [styles.fullWidth]: fullWidth,
      })}
    >
      <section className={classNames(styles.container, className)}>
        {children}
      </section>
    </ContentsWrapper>
  );
};

DataCollectionPageSection.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  inverse: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

DataCollectionPageSection.defaultProps = {
  inverse: false,
  fullWidth: false,
};
