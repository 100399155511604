export function lowestRateDisclaimer(lowestRate, ownerOccupied) {
  const type = ownerOccupied ? 'home loan' : 'investment';
  return `*${lowestRate}% p.a. is our lowest advertised ${type} interest rate when comparing our panel of lenders, which features more than 30 of Australia's biggest banks and specialist lenders. When comparing home loan costs always refer to both the lenders advertised interest rate, and the comparison rate (which is either the same, or in most cases higher). The comparison rate is based on a loan of $150,000 over a term of 25 years. <strong>WARNING:</strong> This comparison rate is true only for the example given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. For variable interest-only loans, comparison rates are based on an initial 5-year Interest Only period. For fixed Interest Only loans, comparison rates are based on an initial Interest Only period equal in length to the fixed period. During an Interest Only period, your Interest Only payments will not reduce your loan balance. This may mean you pay more interest over the life of the loan.`;
}

export function generateDisclaimer(property) {
  if (
    !property ||
    !(
      property.investmentLowestRateManual ||
      (property.lowestRateProduct && property.lowestRateProduct.interestRate)
    )
  ) {
    return [];
  }
  return [
    lowestRateDisclaimer(
      property.investmentLowestRateManual ||
        property.lowestRateProduct.interestRate,
      property.ownerOccupied,
    ),
  ];
}
