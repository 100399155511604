import React from 'react';
import { ModalProvider } from '../contexts/ModalContext';
import Dashboard from './Dashboard';

const DashboardWrapper = (props) => {
  return (
    <ModalProvider>
      <Dashboard {...props} />
    </ModalProvider>
  );
};

export default DashboardWrapper;
