import React from 'react';
import PropTypes from 'prop-types';
import InfoAlertIcon from 'assets/icons/info-alert.svg';
import ButtonWithSpinner from 'components/ButtonWithSpinner/ButtonWithSpinner';
import SVGInline from 'react-svg-inline';
import styles from './DataCollectionLandingContent.css';
import classNames from 'classnames';

const DataCollectioConsentModal = ({
  onPostConsentRequest,
  onCloseModal,
  isSpinnerLoading,
  processConsentStatus,
}) => {
  const url =
    'https://www.equifax.com.au/verificationexchange/sites/default/files/ve-collection-statement.pdf';

  return (
    <>
      <div className={styles.termsAndConditionModal_header}>
        <SVGInline width='20' svg={InfoAlertIcon} />
        <span className={styles.termsAndConditionModal_title}>
          Income verification terms & conditions
        </span>
      </div>

      <div className={styles.termsAndConditionModal_body}>
        <p className={styles._spacer}>
          Income verification allows your employment details to be verified
          securely via Equifax. To accept, click &ldquo;I accept terms and
          conditions&ldquo; below.
        </p>

        <p>
          So that my application can be assessed, I consent to you and your
          service provider, Equifax Verification Exchange® (and, where you act
          as a broker or agent, my prospective transaction counterparty and
          Equifax Verification Exchange® as its service provider) collecting,
          using and disclosing:
        </p>

        <ul className={styles.disclosingList}>
          <li>
            to my employers (or to payroll or other service providers who might
            act on behalf of my employers), my personal information to identify
            me; and
          </li>
          <li>
            my employment income, history and related information obtained from
            such parties to allow you (or that other party, as relevant) to
            verify those matters.
          </li>
        </ul>

        <p className={styles.closingInfo}>
          I consent to Equifax Verification Exchange® using and disclosing my
          personal information in accordance with its{' '}
          <a
            className={styles.modalLink}
            href={url}
            target='_blank'
            rel='noreferrer'
          >
            Verification Exchange Collection Statement
          </a>
          .
        </p>
      </div>

      <div className={classNames(styles.buttonGroup, styles.modalFooter)}>
        <ButtonWithSpinner
          onClick={onCloseModal}
          theme='linkButtonTheme'
          disabled={isSpinnerLoading && processConsentStatus.agree}
          className={
            isSpinnerLoading && processConsentStatus.disagree
              ? styles.buttonWithSnipperLoading
              : styles.buttonWithSnipperDisagree
          }
          showSpinner={isSpinnerLoading && processConsentStatus.disagree}
          label=' I don&lsquo;t consent'
          labelWhileSpinning=' I don&lsquo;t consent'
        />

        <ButtonWithSpinner
          onClick={onPostConsentRequest}
          disabled={isSpinnerLoading && processConsentStatus.disagree}
          className={
            isSpinnerLoading && processConsentStatus.agree
              ? styles.buttonWithSnipperLoading
              : styles.buttonWithSnipper
          }
          showSpinner={isSpinnerLoading && processConsentStatus.agree}
          label='I accept terms and conditions'
          labelWhileSpinning='I accept terms and conditions'
        />
      </div>
    </>
  );
};

DataCollectioConsentModal.propTypes = {
  onPostConsentRequest: PropTypes.func,
  onCloseModal: PropTypes.func,
  isSpinnerLoading: PropTypes.bool,
  processConsentStatus: PropTypes.object,
};
export default DataCollectioConsentModal;
