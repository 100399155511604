import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import {
  ABOUT_PROPERTY_PATH,
  BANKING_PREFERENCES_PATH,
  BUYER_SCENARIOS_PATH,
  CONTACT_DETAILS_PATH,
  PASSWORD_RECOVERY_PATH,
  COMPARE_PAGE_PATH,
  EXISTING_PROPERTY_PATH,
  GET_STARTED_PATH,
  REFINANCING_DETAILS_PATH,
  REASON_FOR_REFINANCE_PATH,
  REFINANCE_PROPERTY_PATH,
  SALE_REPORT_PATH,
  CASH_FLOW_REPORT_PATH,
  PROPERTY_RENT_PATH,
  LOAN_REPORT_PATH,
  LOGIN_CAPTURE_PATH,
  LOGIN_FOR_SIGNUP_PATH,
  FIRST_HOME_OWNERS_GRANT_PATH,
  STAMP_DUTY_PATH,
  PRODUCT_DETAIL_PATH,
  APPLICATION_PROGRESS_PATH,
  APPLY_BASE_PATH,
  INTEREST_SAVING_PATH,
  DASHBOARD_BASE_PATH,
  BORROWING_CAPACITY_PATH,
  UNSUBSCRIBE_PATH,
  HELP_PATH,
  EMAIL_VERIFICATION_PATH,
  PASSWORD_RESET_PATH,
  NEXT_STEPS_PATH,
  PRIVACY_POLICY_PATH,
  PREFERENCES_PATH,
  FUTURE_PATH,
  TEAM_PATH,
  SERVICES_PATH,
  ROOT_PATH,
  DATA_COLLECTION_LANDING_PATH,
  DATA_COLLECTION_ID_VERIFICATION_PATH,
  DATA_COLLECTION_BANK_STATEMENT_PATH,
  DATA_COLLECTION_STATUS_POLLING_PATH,
  DATA_COLLECTION_ID_MANUAL_VERIFICATION_PATH,
  DATA_COLLECTION_CREDIT_CHECK_PATH,
  BROKER_LOGIN_PATH,
  CONTEXT_PAGE_PATH,
} from 'shared/constants/paths';

import LandingPage from 'containers/LandingPage';
import AboutProperty from 'containers/AboutProperty';
import BankingPreferences from 'containers/BankingPreferences';
import BuyerScenarios from 'containers/BuyerScenarios';
import ProductComparePage from 'containers/ProductComparePage';
import GetStarted from 'containers/GetStarted';
import ContactDetails from 'containers/ContactDetails';
import SharedLogin from 'containers/SharedLogin';
import NextStepsPage from 'containers/NextStepsPage';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import EmailVerification from 'containers/EmailVerification';
import PasswordReset from 'containers/PasswordReset';

import RefinancingDetails from 'containers/RefinancingDetails';
import RefinancingReasons from 'containers/RefinancingReasons';
import ExistingProperty from 'containers/ExistingProperty';
import SaleReport from 'containers/SaleReport';
import StampDuty from 'containers/StampDuty';
import CashFlowReport from 'containers/CashFlowReport';
import PropertyRent from 'containers/PropertyRent';
import FirstHomeOwnersGrant from 'containers/FirstHomeOwnersGrant';
import LoanReport from 'containers/LoanReport';
import ProductDetail from 'containers/ProductDetail';
import ApplicationProgress from 'containers/ApplicationProgress';
import InterestSaving from 'containers/InterestSaving';
import ErrorPage from 'containers/ErrorPage';
import BorrowingCapacity from 'containers/BorrowingCapacity';
import UnsubscribePage from 'containers/UnsubscribePage';
import ClientLogin from 'containers/ClientLogin';
import PasswordResetLanding from 'containers/PasswordResetLanding';
import GoalPreferences from 'containers/goal/GoalPreferences/GoalPreferences';
import GoalFuture from 'containers/goal/GoalFuture/GoalFuture';
import GoalTeamApplyWrapper from 'containers/goal/GoalTeam/GoalTeamApplyWrapper';
import GoalServices from 'containers/goal/GoalServices/GoalServices';
import HelpPageRoutes from 'routes/HelpPageRoutes';

import ExpensePopup from 'components/Popups/ExpensePopup';
import LiabilityPopup from 'components/Popups/LiabilityPopup';
import PropertyPopup from 'components/Popups/PropertyPopup';
import FundingPopup from 'components/Popups/FundingPopup';
import IncomePopup from 'components/Popups/IncomePopup';
import AssetPopup from 'components/Popups/AssetPopup';
import CoapplicantPopup from 'components/Popups/CoapplicantPopup';
import ClientPopup from 'components/Popups/ClientPopup';
import DocumentPopup from 'components/Popups/DocumentPopup';
import EmploymentPopup from 'components/Popups/EmploymentPopup';
import AddressPopup from 'components/Popups/AddressPopup';
import { featureFlags } from 'lib/rollout';

import {
  loanReportPopups,
  saleReportPopups,
  cashFlowReportPopups,
} from 'constants/reportMetadata';

import propsMixin from 'hocs/propsMixin';
import {
  RouteForLoggedin,
  ApplyRouteForLoggedin,
  RouteForNonLoggedin,
  CampaignRedirect,
  SetFeatureByRoute,
  PopupWithParentPath,
  routeComponentPropTypes,
  reportRoutes,
  NZExcludedRoute,
  updateLoginPopupRoutes,
  RouteWithParentWrapperForLoggedin,
  RequestPrivacyConsentRoute,
  RouteWithAdvisorData,
} from './helpers';

import AUTH_INTENTS from 'constants/authIntents';
import { extractQueries } from '../lib/utils/browserUtils';
import DataCollectionLanding from 'containers/data-collection/DataCollectionLanding';
import DataCollectionIDVerification from 'containers/data-collection/DataCollectionIDVerification';
import DataCollectionBankStatementWrapper from '../containers/data-collection/DataCollectionBankStatementWrapper';
import DataCollectionStatusPolling from 'containers/data-collection/DataCollectionStatusPolling';
import DataCollectionManualIDVerification from 'containers/data-collection/DataCollectionManualIDVerification';
import DashboardWrapper from 'containers/DashboardWrapper';
import ApplyOnlineWrapper from 'containers/ApplyOnlineWrapper';
import DataCollectionManualCreditRetry from 'containers/data-collection/DataCollectionCreditCheckRetry';
import BrokerLogin from 'containers/BrokerLogin';
import LocalStorageProxy from 'lib/localStorageProxy';
import ContextPage from 'containers/ContextPage';

const LoginComponent = ({ location, path }) => {
  const {
    shared: token,
    section,
    recovery: recoveryToken,
    clientId,
    loanApplicationId,
  } =
    (location &&
      (location.query ||
        (location.search && extractQueries(location.search)))) ||
    {};

  const sessionToken = LocalStorageProxy.token;
  const sharedURL = window.location.href;
  if (recoveryToken && sessionToken) {
    LocalStorageProxy.logoutRedirectUri = sharedURL;
  }

  if (token || recoveryToken) {
    return (
      <RouteForNonLoggedin
        component={propsMixin(SharedLogin, {
          token,
          recoveryToken,
          clientId,
          loanApplicationId,
          sections: section || [],
        })}
        path={path}
      />
    );
  }
  return (
    <RouteForNonLoggedin
      component={propsMixin(ClientLogin, { intent: AUTH_INTENTS.LOGIN })}
      path={path}
    />
  );
};

LoginComponent.propTypes = routeComponentPropTypes;

export const ApplyOnlineRoutes = ({ match }) => (
  <div>
    <Route path={match.path} component={ApplyOnlineWrapper} />
    {!match.isExact && (
      <Switch>
        <Route
          path={`${match.path}/expense/:id`}
          component={PopupWithParentPath(ExpensePopup, match.url)}
        />
        <Route
          path={`${match.path}/property/:id`}
          component={PopupWithParentPath(PropertyPopup, match.url)}
        />
        <Route
          path={`${match.path}/liability/:id`}
          component={PopupWithParentPath(LiabilityPopup, match.url)}
        />
        <Route
          path={`${match.path}/funding/:id`}
          component={PopupWithParentPath(FundingPopup, match.url)}
        />
        <Route
          path={`${match.path}/income/:id`}
          component={PopupWithParentPath(IncomePopup, match.url)}
        />
        <Route
          path={`${match.path}/asset/:id`}
          component={PopupWithParentPath(AssetPopup, match.url)}
        />
        <Route
          exact
          path={`${match.path}/coapplicant/:id`}
          component={PopupWithParentPath(CoapplicantPopup, match.url)}
        />
        <Route
          exact
          path={`${match.path}/client/:id`}
          component={PopupWithParentPath(ClientPopup, match.url)}
        />
        <Route
          path={`${match.path}/document/:id`}
          component={PopupWithParentPath(DocumentPopup, match.url)}
        />
        <Route
          path={`${match.path}/employment/:id`}
          component={PopupWithParentPath(EmploymentPopup, match.url)}
        />
        <Route
          path={`${match.path}/address/:id`}
          component={PopupWithParentPath(AddressPopup, match.url)}
        />
        {updateLoginPopupRoutes(match)}
        <Redirect to={match.url} />
      </Switch>
    )}
  </div>
);

ApplyOnlineRoutes.propTypes = routeComponentPropTypes;

const LoanReportWithPopups = reportRoutes(LoanReport, loanReportPopups);
const SaleReportWithPopups = reportRoutes(SaleReport, saleReportPopups);
const CashFlowReportWithPopups = reportRoutes(
  CashFlowReport,
  cashFlowReportPopups,
);

export default function routes() {
  const isManualIdVerificationFFEnabled = featureFlags[
    'consumer.manual-id-verification'
  ].isEnabled();

  return (
    <Switch>
      <Route path={HELP_PATH} component={HelpPageRoutes} />
      <RouteForNonLoggedin
        path='/authorization-code/callback'
        component={LoginCallback}
      />
      <RouteForNonLoggedin exact path={ROOT_PATH} component={LandingPage} />
      <RouteForNonLoggedin
        component={AboutProperty}
        path={ABOUT_PROPERTY_PATH}
      />
      <RouteForNonLoggedin
        component={BankingPreferences}
        path={BANKING_PREFERENCES_PATH}
      />
      <RouteForNonLoggedin
        component={BuyerScenarios}
        path={BUYER_SCENARIOS_PATH}
      />
      <RouteForNonLoggedin
        component={CashFlowReportWithPopups}
        path={CASH_FLOW_REPORT_PATH}
      />
      <RouteForNonLoggedin component={PropertyRent} path={PROPERTY_RENT_PATH} />
      <RouteForNonLoggedin
        component={ContactDetails}
        path={CONTACT_DETAILS_PATH}
      />
      <RouteForNonLoggedin
        component={PasswordReset}
        path={PASSWORD_RECOVERY_PATH}
      />
      <LoginComponent path={LOGIN_CAPTURE_PATH} />
      <RouteForNonLoggedin
        component={propsMixin(ClientLogin, { intent: AUTH_INTENTS.SIGNUP })}
        path={LOGIN_FOR_SIGNUP_PATH}
      />
      <RouteForNonLoggedin
        component={FirstHomeOwnersGrant}
        path={FIRST_HOME_OWNERS_GRANT_PATH}
      />
      <RouteForNonLoggedin component={GetStarted} path={GET_STARTED_PATH} />
      <RouteForNonLoggedin
        component={LoanReportWithPopups}
        path={LOAN_REPORT_PATH}
      />
      <RouteForNonLoggedin
        component={RefinancingDetails}
        path={REFINANCING_DETAILS_PATH}
      />
      <RouteForNonLoggedin
        component={RefinancingReasons}
        path={REASON_FOR_REFINANCE_PATH}
      />
      <RouteForNonLoggedin
        component={SaleReportWithPopups}
        path={SALE_REPORT_PATH}
      />
      <RouteForNonLoggedin
        component={ExistingProperty}
        path={EXISTING_PROPERTY_PATH}
      />
      <RouteForNonLoggedin
        component={ExistingProperty}
        path={REFINANCE_PROPERTY_PATH}
      />
      <RouteForNonLoggedin component={StampDuty} path={STAMP_DUTY_PATH} />
      <RouteForNonLoggedin
        component={PasswordResetLanding}
        path={PASSWORD_RESET_PATH}
      />
      <NZExcludedRoute
        component={ProductComparePage}
        path={COMPARE_PAGE_PATH}
      />
      <Route
        component={ProductDetail}
        path={`${PRODUCT_DETAIL_PATH}/:productId`}
      />
      <Route
        component={UnsubscribePage}
        path={`${UNSUBSCRIBE_PATH}/:trigger`}
      />
      <Route component={EmailVerification} path={EMAIL_VERIFICATION_PATH} />
      <RouteWithAdvisorData component={NextStepsPage} path={NEXT_STEPS_PATH} />
      <RequestPrivacyConsentRoute
        path={PRIVACY_POLICY_PATH}
        component={PrivacyPolicy}
      />
      <ApplyRouteForLoggedin
        component={DashboardWrapper}
        path={DASHBOARD_BASE_PATH}
      />
      <RouteForLoggedin
        component={BorrowingCapacity}
        path={BORROWING_CAPACITY_PATH}
      />
      <RouteWithParentWrapperForLoggedin
        component={GoalPreferences}
        path={`${APPLY_BASE_PATH}/:loanApplicationId${PREFERENCES_PATH}/:section`}
      />
      <RouteWithParentWrapperForLoggedin
        component={GoalFuture}
        path={`${APPLY_BASE_PATH}/:loanApplicationId${FUTURE_PATH}/:section/:familyId/:clientId?`}
      />
      <RouteWithParentWrapperForLoggedin
        component={GoalServices}
        path={`${APPLY_BASE_PATH}/:loanApplicationId${SERVICES_PATH}`}
      />
      <RouteWithParentWrapperForLoggedin
        component={GoalTeamApplyWrapper}
        path={`${APPLY_BASE_PATH}/:loanApplicationId${TEAM_PATH}`}
      />
      <ApplyRouteForLoggedin
        component={ApplyOnlineRoutes}
        path={`${APPLY_BASE_PATH}/:loanApplicationId/:section?`}
      />
      <RouteForLoggedin
        component={ApplicationProgress}
        path={APPLICATION_PROGRESS_PATH}
      />
      <RouteForLoggedin
        component={InterestSaving}
        path={INTEREST_SAVING_PATH}
      />

      <Route path='/campaign/:slug' component={CampaignRedirect} />
      <Route path='/ab/:feature/:state' component={SetFeatureByRoute} />
      <Route
        exact
        path='/throw-sentry-error'
        component={() => {
          throw new Error('Does Sentry work?');
        }}
      />

      <RouteForLoggedin
        exact
        component={DataCollectionLanding}
        path={DATA_COLLECTION_LANDING_PATH}
      />
      <RouteForLoggedin
        exact
        component={
          isManualIdVerificationFFEnabled
            ? DataCollectionManualIDVerification
            : DataCollectionIDVerification
        }
        path={DATA_COLLECTION_ID_VERIFICATION_PATH}
      />
      <RouteForLoggedin
        exact
        component={DataCollectionManualIDVerification}
        path={DATA_COLLECTION_ID_MANUAL_VERIFICATION_PATH}
      />
      <RouteForLoggedin
        exact
        component={DataCollectionBankStatementWrapper}
        path={DATA_COLLECTION_BANK_STATEMENT_PATH}
      />
      <RouteForLoggedin
        exact
        component={DataCollectionStatusPolling}
        path={DATA_COLLECTION_STATUS_POLLING_PATH}
      />
      <RouteForLoggedin
        exact
        component={DataCollectionManualCreditRetry}
        path={DATA_COLLECTION_CREDIT_CHECK_PATH}
      />
      <RouteForLoggedin
        exact
        component={ContextPage}
        path={CONTEXT_PAGE_PATH}
      />
      <Route path={`${BROKER_LOGIN_PATH}/:id/:id`} component={BrokerLogin} />
      <ErrorPage error={{ status: 404 }} />
    </Switch>
  );
}
