import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getLenderStyle } from 'lib/utils/imageUtils';

import styles from './LogoGrid.css';

const LogoGrid = ({ lenders }) => {
  const [showLogos, setShowLogos] = useState({});

  const renderLogo = (lender) => (
    <li
      key={lender.lenderId}
      className={styles.item}
      style={getLenderStyle(lender.key, showLogos)}
    >
      <img
        className={styles.logo}
        src={lender.logoUrl}
        alt={lender.key}
        data-image={lender.logoUrl}
        onLoad={() => setShowLogos((s) => ({ ...s, [lender.key]: true }))}
      />
    </li>
  );

  return <ol className={styles.root}>{lenders.map(renderLogo)}</ol>;
};

LogoGrid.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LogoGrid;
