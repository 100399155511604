import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import scenarioActions from 'actions/scenarioActions';

import MyCRMLogin from 'components/MyCRMLogin/MyCRMLogin';
function ClientLogin(props) {
  const { requestLogin, intent, redirect, fullPageSpinnerLoading } = props;

  const handleSubmit = (username, password) => {
    requestLogin({ username, password, intent, redirect });
  };

  return (
    <MyCRMLogin
      headingTitle='Log in to your profile'
      handleSubmit={handleSubmit}
      centeredTitle
      showFooter
      fullPageSpinnerLoading={fullPageSpinnerLoading}
    />
  );
}

ClientLogin.propTypes = {
  requestLogin: PropTypes.func,
  redirect: PropTypes.string,
  intent: PropTypes.string,
  fullPageSpinnerLoading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    redirect: ownProps.history.location.query.redirect,
    fullPageSpinnerLoading: state.UISettings.fullPageSpinnerLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin: scenarioActions.requestLogin,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClientLogin);
