import React from 'react';
import Spinner from 'components/Spinner/Spinner';

const BrokerLogin = () => (
  <>
    <Spinner loading variant='default' />
  </>
);

export default BrokerLogin;
