import React from 'react';
import { ModalProvider } from '../contexts/ModalContext';
import ApplyOnline from './ApplyOnline';

const ApplyOnlineWrapper = (props) => {
  return (
    <ModalProvider>
      <ApplyOnline {...props} />
    </ModalProvider>
  );
};

export default ApplyOnlineWrapper;
