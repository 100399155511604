import _ from 'lodash';
import moment from 'moment';

import locale from 'config/locale';
import { generateOptions } from 'lib/optionHelpers';
import {
  CLIENT_RESIDENCIES,
  MARITAL_STATUSES,
} from 'shared/constants/myCRMTypes/clients';
import {
  ZONINGS,
  PROPERTY_TYPES_PER_ZONING,
  PROPERTY_STATUSES,
  PROPERTY_PRIMARY_PURPOSES,
} from 'shared/constants/myCRMTypes/property';
import {
  ASSET_CATEGORIES,
  ASSET_TYPES_PER_CATEGORY,
} from 'shared/constants/myCRMTypes/assets';
import {
  liabilityCategories,
  liabilityCategoriesAU,
  liabilityTypesPerCategory,
} from 'shared/constants/myCRMTypes/liabilities';
import {
  EMPLOYMENT_STATUSES,
  EMPLOYMENT_TYPES,
  EMPLOYMENT_BASISES,
  EMPLOYMENT_EMPLOYER_TYPES,
  EMPLOYMENT_NATURE,
} from 'shared/constants/myCRMTypes/employments';
import {
  ADDRESS_TYPE_CURRENT,
  ADDRESS_TYPE_PREVIOUS,
  ADDRESS_OWNERSHIP_TYPES,
} from 'shared/constants/myCRMTypes/address';
import { DEFAULT_DEPENDENT_MIN_DATE } from 'shared/constants/defaults';
import { COUNTRIES } from 'shared/constants/myCRMTypes/countries';
import lenders from 'shared/lib/lenderHelper';
import * as options from 'shared/constants/options';
import { makeArrayFromRange } from 'lib/utils/dataUtils';

export const YES_NO_OPTIONS = [
  { key: 'true', value: true, label: 'Yes' },
  { key: 'false', value: false, label: 'No' },
];

export const SELF_EMPLOYED_OPTIONS = [
  { key: 'false', value: false, label: 'Employee' },
  { key: 'true', value: true, label: 'Self Employed' },
];

export const OWNER_OCCUPIED_OPTIONS = [
  {
    key: 'true',
    value: options.OWNER_OCCUPIED_RESIDENCE,
    label: 'Home',
    name: 'Home',
  },
  {
    key: 'false',
    value: options.OWNER_OCCUPIED_INVESTMENT,
    label: 'Investment',
    name: 'Investment',
  },
];

export const GENDER_OPTIONS = [
  { key: 'male', value: 'male', label: 'Male', name: 'Male' },
  { key: 'female', value: 'female', label: 'Female', name: 'Female' },
];

export const GENDER_OPTIONS_WITH_UNDISCLOSED = [
  ...GENDER_OPTIONS,
  {
    key: 'undisclosed',
    value: 'undisclosed',
    label: 'Undisclosed',
    name: 'Undisclosed',
  },
];

export const CLIENT_IS_DEPENDENT_OPTIONS = [
  { key: 'partner', value: false, label: 'Partner', name: 'Partner' },
  {
    key: 'dependent',
    value: true,
    label: 'Dependent Child',
    name: 'Dependent Child',
  },
];

export const FHOG_OPTIONS = [
  {
    key: 'true',
    value: 'true',
    label: 'Yes - I’m eligible',
    icon: 'mi-check-with-circle',
  },
  {
    key: 'false',
    value: 'false',
    label: 'No - I’m not eligible',
    icon: 'mi-cross-with-circle',
  },
  {
    key: 'unsure',
    value: 'unsure',
    label: 'I’m not sure yet',
    icon: 'mi-question-with-circle',
  },
];

export const STATE_OPTIONS = [
  { value: 'ACT', name: 'Australian Capital Territory' },
  { value: 'NSW', name: 'New South Wales' },
  { value: 'NT', name: 'Northern Territory' },
  { value: 'QLD', name: 'Queensland' },
  { value: 'SA', name: 'South Australia' },
  { value: 'TAS', name: 'Tasmania' },
  { value: 'VIC', name: 'Victoria' },
  { value: 'WA', name: 'Western Australia' },
];

export const DEPENDENTS_OPTIONS = [
  { value: 1, name: '1' },
  { value: 2, name: '2' },
  { value: 3, name: '3' },
  { value: 4, name: '4' },
  { value: 5, name: '5+' },
];

export * from 'shared/constants/options';

export const INCOME_OPTIONS = [
  { value: true, name: 'Gross' },
  { value: false, name: 'Net' },
];

export const FREQUENCY_OPTIONS = [
  { value: options.WEEKLY, name: 'per week' },
  { value: options.FORTNIGHTLY, name: 'per fortnight' },
  { value: options.MONTHLY, name: 'per month' },
  { value: options.QUARTERLY, name: 'per quarter' },
  { value: options.HALF_YEARLY, name: 'per half year' },
  { value: options.YEARLY, name: 'per year' },
];

export const STRUCTURE_FREQUENCY_OPTIONS = [
  { value: options.WEEKLY, name: 'Weekly', label: 'Week' },
  { value: options.FORTNIGHTLY, name: 'Fortnightly', label: 'Fortnight' },
  { value: options.MONTHLY, name: 'Monthly', label: 'Month' },
];

export const BORROWER = 'borrower';
export const GUARANTOR = 'guarantor';
export const PERSON = 'person';
export const PARTNER_OR_SPOUSE = 'partner';
export const COMPANY_OR_TRUST = 'company';

export const PERSON_CONTACT_TYPES = [PERSON, PARTNER_OR_SPOUSE];

export const FOUND_PROPERTY_OPTIONS = [
  { key: 'yes', value: 'true', label: 'Yes', name: 'Yes' },
  { key: 'no', value: 'false', label: 'Still looking', name: 'Still Looking' },
];

export const messages = {
  householdShape: {
    [options.SINGLE_HOUSEHOLD]: 'Single - no children',
    [options.SINGLE_PARENT_HOUSEHOLD]: 'Single - with dependent children',
    [options.COUPLE_HOUSEHOLD]: 'Couple - no children',
    [options.COUPLE_PARENT_HOUSEHOLD]: 'Couple - with dependent children',
  },
  contactType: {
    [PARTNER_OR_SPOUSE]: 'Partner / Spouse',
    [PERSON]: 'Person',
    [COMPANY_OR_TRUST]: 'Company / Trust',
  },
  contactRole: {
    [BORROWER]: 'Borrower',
    [GUARANTOR]: 'Guarantor',
  },
  propertyOwned: {
    [options.PROPERTY_OWNED]: 'Own',
    [options.PROPERTY_RENT]: 'Rent',
    [options.PROPERTY_BOARDING]: 'Boarding',
    [options.PROPERTY_WITH_PARENTS]: 'Living with Parents',
  },
  myCRMFrequency: {
    [options.WEEKLY]: 'per week',
    [options.FORTNIGHTLY]: 'per fortnight',
    [options.MONTHLY]: 'per month',
    [options.QUARTERLY]: 'per quarter',
    [options.HALF_YEARLY]: 'per half year',
    [options.YEARLY]: 'per year',
  },
  incomeFrequency: {
    [options.WEEKLY]: 'Weekly',
    [options.FORTNIGHTLY]: 'Fortnightly',
    [options.MONTHLY]: 'Monthly',
    [options.QUARTERLY]: 'Quarterly',
    [options.HALF_YEARLY]: 'Half Yearly',
    [options.YEARLY]: 'Yearly',
  },
  ageUnit: {
    [options.YEARS]: 'Years',
    [options.MONTHS]: 'Months',
  },
};

const optionsFromMessages = (keyOnMessages) =>
  Object.keys(messages[keyOnMessages]).map((k) => ({
    value: k,
    key: k,
    name: messages[keyOnMessages][k],
    label: messages[keyOnMessages][k],
  }));

export const householdShapeOptions = _.partial(
  optionsFromMessages,
  'householdShape',
);

export const CONTACT_TYPE_OPTIONS = _.partial(
  optionsFromMessages,
  'contactType',
);
export const CONTACT_ROLE_OPTIONS = _.partial(
  optionsFromMessages,
  'contactRole',
);
export const MYCRM_FREQUENCY_OPTIONS = _.partial(
  optionsFromMessages,
  'myCRMFrequency',
);
export const INCOME_FREQUENCY_OPTIONS = _.partial(
  optionsFromMessages,
  'incomeFrequency',
);
export const AGE_UNIT_OPTIONS = _.partial(optionsFromMessages, 'ageUnit');
export const PROPERTY_OWNED_OPTIONS = _.partial(
  optionsFromMessages,
  'propertyOwned',
);

export const MARITAL_STATUS_OPTIONS = generateOptions(MARITAL_STATUSES);

export const ZONING_OPTIONS = generateOptions(ZONINGS);
export const PROPERTY_STATUS_OPTIONS = generateOptions(PROPERTY_STATUSES);
export const propertyTypeOptions = (zoning) =>
  generateOptions(PROPERTY_TYPES_PER_ZONING[zoning], locale.data.countryCode);
export const PROPERTY_PRIMARY_PURPOSE_OPTIONS = generateOptions(
  PROPERTY_PRIMARY_PURPOSES,
);

export const ASSET_CATEGORY_OPTIONS = generateOptions(ASSET_CATEGORIES);
export const assetTypeOptions = (category) =>
  generateOptions(ASSET_TYPES_PER_CATEGORY[category], locale.data.countryCode);
export const assetCategoryWithDefaultType = (category) =>
  assetTypeOptions(category).length === 1;

export const liabilityCategoryOptions = (isAU) =>
  generateOptions(isAU ? liabilityCategoriesAU : liabilityCategories);

export const liabilityTypeOptions = (category) =>
  generateOptions(liabilityTypesPerCategory[category], locale.data.countryCode);

export const EMPLOYMENT_STATUS_OPTIONS = generateOptions(EMPLOYMENT_STATUSES);
export const employmentTypeOptions = () =>
  generateOptions(EMPLOYMENT_TYPES, locale.data.countryCode);
export const EMPLOYMENT_BASIS_OPTIONS = generateOptions(EMPLOYMENT_BASISES);
export const EMPLOYMENT_EMPLOYER_TYPE_OPTIONS = generateOptions(
  EMPLOYMENT_EMPLOYER_TYPES,
);
export const EMPLOYMENT_NATURE_OPTIONS = generateOptions(EMPLOYMENT_NATURE);

export const ADDRESS_TYPE_OPTIONS = [
  {
    value: ADDRESS_TYPE_CURRENT.id,
    key: 'current',
    label: 'Yes',
    displayName: ADDRESS_TYPE_CURRENT.name,
  },
  {
    value: ADDRESS_TYPE_PREVIOUS.id,
    key: 'previous',
    label: 'No',
    displayName: ADDRESS_TYPE_PREVIOUS.name,
  },
];
export const addressOwnershipTypeOptions = generateOptions(
  ADDRESS_OWNERSHIP_TYPES,
);

export const RESIDENCY_OPTIONS = generateOptions(CLIENT_RESIDENCIES);
export const COUNTRY_OPTIONS = generateOptions(COUNTRIES);

export const LENDER_OPTIONS = (isAutoComplete) => {
  const allLenders = lenders.all;

  const lenderName = isAutoComplete ? 'text' : 'name';
  const lenderId = isAutoComplete ? 'key' : 'value';

  const lenderOptions = allLenders.map((lender) => ({
    [lenderName]: lender.lenderName,
    [lenderId]: lender.lenderId,
    ...(!isAutoComplete ? { key: lender.key } : {}),
  }));

  const otherOption = {
    [lenderName]: 'Other',
    [lenderId]: -1,
    ...(!isAutoComplete ? { key: 'Other' } : {}),
  };

  return _.sortBy(lenderOptions, (lender) =>
    lender[lenderName].toLowerCase(),
  ).concat(otherOption);
};

const generateAges = () => {
  const { years: maxYear } = moment().toObject();
  const { years: minYear } = DEFAULT_DEPENDENT_MIN_DATE.toObject();
  const labels = _.range(minYear, maxYear).reduce(
    (result, item) => {
      result[item] = maxYear - item;
      return result;
    },
    { [maxYear]: '0-1' },
  );

  return generateOptions(makeArrayFromRange(minYear, maxYear, labels, true));
};
export const AGE_OPTIONS = generateAges();

export const PERCENTAGE_OPTIONS = [0.05, 0.1, 0.15, 0.2];

export const ASSET_YEAR_OPTIONS = makeArrayFromRange(
  new Date().getFullYear(),
  1928,
);

export const SPLIT_PERCENTAGE_OPTIONS = [0.25, 0.33, 0.5, 0.66, 0.75];

export const ASYNC_REQUEST_TYPE = {
  FORM_POP_UP_REQUEST: 'FORM_POP_UP_REQUEST',
};
