import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import _ from 'lodash';
import moment from 'moment';

import { LOAN_PURPOSE_INVESTMENT } from 'shared/constants/loanPurposes';
import { BUYER_SCENARIO_INVEST } from 'shared/constants/loanScenarios';
import {
  DEFAULT_CURRENT_INTEREST_RATE,
  DEFAULT_EXISTING_PROPERTY_ID,
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_LOAN_AMOUNT,
} from 'shared/constants/defaults';
import {
  OWNER_OCCUPIED_INVESTMENT,
  OWNER_OCCUPIED_RESIDENCE,
  MONTHLY,
  WEEKLY,
} from 'shared/constants/options';
import { convertFrequency } from 'shared/lib/numbrero';
import loanAmountCalculator, {
  calculateCosts,
  calculateContributions,
  incomeFromSellingExistingProperties,
} from 'shared/lib/loanAmountCalculator';
import repaymentCalculator, {
  interestOnlyRepaymentCalculator,
} from 'shared/lib/repaymentCalculator';
import { formatMobile, convertToPercentage } from 'lib/utils/numberUtils';
import { lvrCalculator, isEmptyObject } from 'shared/lib/utils';
import { isRefinanceOrRenovate } from 'shared/lib/checkLoanPurpose';
import { paramsId, paramCacheResolver } from 'selectors/sharedSelectors';
import { quickLmi } from 'selectors/otherSelectors';
import { OWNER_OCCUPIED, INVESTMENT } from 'shared/constants/productTypes';

export const scenario = (state) => state.scenario;
export const properties = (state) => state.scenario.properties;
export const getName = (state) => state.scenario.name;
export const getLoanPurpose = (state) => state.scenario.loanPurpose;
export const recoveryTokenStatus = (state) =>
  state.scenario.recoveryTokenStatus;
export const refinanceReasons = (state) =>
  state.scenario.refinanceReasons || {};
export const isLookingForBetterDeal = (state) =>
  !!refinanceReasons(state).betterDeal;
export const defaultExistingProperty = (state) =>
  state.scenario.properties[DEFAULT_EXISTING_PROPERTY_ID];
export const prospectiveProperty = (state) =>
  state.scenario.properties[PROSPECTIVE_PROPERTY_ID];
export const advisor = (state) => state.advisor;
export const isFork = (state) => !!state.scenario.forkChoice;

function rentalValue(property, frequency) {
  return property && property.rentalAmount
    ? convertFrequency(
        property.rentalAmount,
        property.rentalFrequency,
        frequency,
      )
    : 0;
}

const propertyFromParamsId = createCachedSelector(
  paramsId,
  properties,
  (id, p) => p[id],
)(paramCacheResolver);

export const propertyValue = createSelector(propertyFromParamsId, (p) =>
  _.get(p, 'value'),
);
export const prospectivePropertyValue = createSelector(
  prospectiveProperty,
  (p) => _.get(p, 'value'),
);
export const prospectivePropertyState = createSelector(
  prospectiveProperty,
  (p) => _.get(p, 'state'),
);
export const prospectivePropertyOwnerOccupied = createSelector(
  prospectiveProperty,
  (p) => !!p && !!p.ownerOccupied,
);

export const propertyManagementFee = createSelector(propertyFromParamsId, (p) =>
  _.get(p, 'managementFee'),
);
export const propertyManagementFeePercentage = createSelector(
  propertyFromParamsId,
  (p) =>
    p && p.managementFeePercentage !== undefined
      ? convertToPercentage(p.managementFeePercentage)
      : undefined,
);

export const propertyVacancyFee = createSelector(propertyFromParamsId, (p) =>
  _.get(p, 'vacancyFee'),
);
export const propertyVacancyFeePercentage = createSelector(
  propertyFromParamsId,
  (p) =>
    p && p.vacancyFeePercentage !== undefined
      ? convertToPercentage(p.vacancyFeePercentage)
      : undefined,
);

export const propertyWeeklyRentalIncome = createSelector(
  propertyFromParamsId,
  (p) => rentalValue(p, WEEKLY),
);

export const propertyWeeklyRepairFee = createSelector(
  propertyFromParamsId,
  (p) => _.get(p, 'weeklyRepairFee', 0),
);

export const propertyAnnualRepairFee = createSelector(
  propertyWeeklyRepairFee,
  (v) => v * 52,
);

export const deposit = createSelector(prospectiveProperty, (property) =>
  _.get(property, 'depositAmount'),
);

export const conveyancerCost = createSelector(
  propertyFromParamsId,
  (property) => _.get(property, 'conveyancerCost'),
);

export const movingCost = createSelector(propertyFromParamsId, (p) =>
  _.get(p, 'movingCost', 0),
);

export const stampDuty = createSelector(prospectiveProperty, (property) =>
  _.get(property, 'totalFee'),
);

export const firstHomeOwnersGrant = createSelector(
  prospectiveProperty,
  (property) => _.get(property, 'grant') || 0,
);

export const renovationCost = createSelector(propertyFromParamsId, (p) =>
  _.get(p, 'renovationCost'),
);

export const salesFeesAndCharges = createSelector(
  defaultExistingProperty,
  (property) =>
    property
      ? property.realestateFee +
        property.conveyancerCost +
        property.movingCost +
        property.renovationCost
      : undefined,
);

export const mortgageAmount = createSelector(
  defaultExistingProperty,
  (property) => _.get(property, 'mortgageAmount'),
);

export const existingPropertyEquity = createSelector(
  defaultExistingProperty,
  (property) => _.get(property, 'equity'),
);

export const existingPropertyAdsFee = createSelector(
  defaultExistingProperty,
  (property) => _.get(property, 'adsFee'),
);

export const existingPropertyRealEstateCommissionPercentage = createSelector(
  defaultExistingProperty,
  (property) =>
    property && property.realEstateCommissionPercentage !== undefined
      ? convertToPercentage(property.realEstateCommissionPercentage)
      : undefined,
);

export const existingPropertyRealEstateFee = createSelector(
  defaultExistingProperty,
  (property) => _.get(property, 'realestateFee'),
);

export const otherCosts = (state) => {
  const prospective = prospectiveProperty(state);
  return prospective ? prospective.otherCosts : [];
};

export const otherCost = createCachedSelector(
  paramsId,
  prospectiveProperty,
  (id, prospective) => prospective && prospective.otherCosts[id],
)(paramCacheResolver);

export const getIsRefinanceOrRenovate = createSelector(
  getLoanPurpose,
  (loanPurpose) => isRefinanceOrRenovate(loanPurpose),
);

export const existingProperties = createSelector(properties, (a) =>
  _.filter(a, (v, k) => k !== PROSPECTIVE_PROPERTY_ID),
);

export const totalCosts = createSelector(scenario, (a) => calculateCosts(a));

export const monthlyRentalIncome = createSelector(
  prospectiveProperty,
  defaultExistingProperty,
  (a, b) => rentalValue(a, MONTHLY) + rentalValue(b, MONTHLY),
);

export const totalContributions = createSelector(scenario, (a) =>
  calculateContributions(a),
);

function getPropertyInterestRate(property) {
  if (!property) {
    return undefined;
  }
  if (property.investmentLowestRateManual !== undefined) {
    return property.investmentLowestRateManual;
  }
  return property.lowestRateProduct && property.lowestRateProduct.interestRate;
}

export const propertyInterestRate = createSelector(propertyFromParamsId, (p) =>
  getPropertyInterestRate(p),
);

export const lowestRate = createSelector(
  prospectiveProperty,
  defaultExistingProperty,
  (prospective, existing) =>
    getPropertyInterestRate(prospective) || getPropertyInterestRate(existing),
);

// This selector is a temporary solution to deal with the indiscrepency with
// isOwnerOccupied flag - it is sometimes true/false and sometimes a string.
// Default Existing Property should be investment in Rent And Buy New flow,
// but at the moment is set to ownerOccupy = true.
// TODO: refactor ownerOccupy in properties to be purpose, simplify this selector
// to simply get value from property object. This will require refactoring the
// flows so that the correct propery Purpose is being set.
// HERE BE DRAGONS - consider how Rent and Buy New will transfer address to
// Where you live today section in apply.
export const propertyPurpose = createSelector(
  properties,
  getName,
  getLoanPurpose,
  (a, b, c) =>
    _.memoize((id) => {
      if (
        c === LOAN_PURPOSE_INVESTMENT ||
        (b === BUYER_SCENARIO_INVEST && id === DEFAULT_EXISTING_PROPERTY_ID)
      ) {
        return OWNER_OCCUPIED_INVESTMENT;
      }
      const property = a[id];
      if (!property) {
        return undefined;
      }
      if (!property.ownerOccupied || property.ownerOccupied === 'Investment') {
        return OWNER_OCCUPIED_INVESTMENT;
      }
      return OWNER_OCCUPIED_RESIDENCE;
    }),
);

// TODO: Remove this if not used
export const getLoanPurposeProductType = createCachedSelector(
  properties,
  (state, propertyId) => propertyId,
  (propertyList, propertyId) => {
    const property = propertyList[propertyId];

    return property && property.ownerOccupied === 'Investment'
      ? INVESTMENT
      : OWNER_OCCUPIED;
  },
)((state, propertyId) => propertyId);

export const loanAmount = createSelector(
  totalCosts,
  totalContributions,
  (costs, contributions) => costs - contributions || DEFAULT_LOAN_AMOUNT,
);

export const interestOnlyRepayment = createSelector(
  propertyFromParamsId,
  propertyInterestRate,
  loanAmount,
  mortgageAmount,
  (property, rate, loan, mortgage) =>
    interestOnlyRepaymentCalculator(
      rate,
      property.id === PROSPECTIVE_PROPERTY_ID ? loan : mortgage,
    ),
);

export const lvr = createSelector(
  loanAmount,
  prospectivePropertyValue,
  lvrCalculator,
);

export const lmi = createSelector(loanAmount, lvr, quickLmi, (a, b, c) => {
  if (!c || isEmptyObject(c) || b < 0.8) {
    return 0;
  }
  // quick lmi fails for LVRs > 100% so we default to an estimate.
  if (c && !c.maximum) {
    return a * 0.02;
  }
  return c.average;
});

export const incomeFromSellingProperties = createSelector(scenario, (a) =>
  incomeFromSellingExistingProperties(a),
);

export const isPostcodeCountryAUOrNZ = createSelector(
  scenario,
  (a) => a.postcodeCountry === 'AU' || a.postcodeCountry === 'NZ',
);

export const mortgageRepayment = createSelector(
  propertyInterestRate,
  loanAmount,
  (a, b) => repaymentCalculator({ interestRate: a, loanAmount: b }),
);

export const mortgageRepaymentSafetyBuffer = createSelector(
  mortgageRepayment,
  propertyInterestRate,
  loanAmount,
  (a, b, c) => repaymentCalculator({ interestRate: b + 2, loanAmount: c }) - a,
);

export const getMobileFormatted = createSelector(
  scenario,
  (a) => a.mobile && formatMobile(a.mobile),
);

export const getLoanAmount = createSelector(scenario, (a) =>
  loanAmountCalculator(a),
);

export const getCurrentLenderId = createSelector(
  defaultExistingProperty,
  (property) => {
    return property ? property.currentLenderId : undefined;
  },
);

export const getCurrentInterestRate = createSelector(
  defaultExistingProperty,
  (property) =>
    (property && property.currentInterestRate) || DEFAULT_CURRENT_INTEREST_RATE,
);

export const getMortgageAmount = createSelector(
  defaultExistingProperty,
  (property) => (property && property.mortgageAmount) || 0,
);

const getDebtConsolidateAmount = createSelector(
  defaultExistingProperty,
  (property) => (property && property.consolidateDebtAmount) || 0,
);

const getRenovationCost = createSelector(
  defaultExistingProperty,
  (property) => (property && property.renovationCost) || 0,
);

const getAdditionalFunds = createSelector(
  defaultExistingProperty,
  (property) => (property && property.additionalFunds) || 0,
);

export const getTotalLoanAmount = createSelector(
  getMortgageAmount,
  getDebtConsolidateAmount,
  getRenovationCost,
  getAdditionalFunds,
  (mortgageLoanAmount, debtAmount, renoAmount, extraAmount) =>
    mortgageLoanAmount + debtAmount + renoAmount + extraAmount,
);

export const getScenarioForUserSignup = (state) => scenario(state);

export const getExistingPropertyPurpose = createSelector(properties, (a) => {
  const property = a[DEFAULT_EXISTING_PROPERTY_ID];
  if (!property || !property.ownerOccupied) {
    return undefined;
  }

  return property.ownerOccupied;
});

export const getKeepPostcode = createSelector(
  getIsRefinanceOrRenovate,
  isPostcodeCountryAUOrNZ,
  (a, b) =>
    _.memoize((purpose) => {
      let keepPostcode = false;

      if (a || !b) {
        keepPostcode = purpose ? purpose === OWNER_OCCUPIED_RESIDENCE : true;
      }
      return keepPostcode;
    }),
);

export const isAllocatedToBroker = createSelector(scenario, (s) => {
  const { allocatedBrokerFamilyId, isRayWhiteCalculator, agents } = s;
  return (
    !!allocatedBrokerFamilyId ||
    (!!isRayWhiteCalculator && !!agents && agents.length > 0)
  );
});

export const stateTokenExpiryCounter = createSelector(scenario, (s) => {
  const {
    oktaData: { expiresAt },
  } = s;
  return moment.duration(moment(expiresAt).diff(moment())).asSeconds();
});

export const getMobileNumberCountryCode = createSelector(
  scenario,
  (s) => s.mobile.countryCode,
);
