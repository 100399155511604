import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import scenarioActions from 'actions/scenarioActions';
import MyCRMLogin from 'components/MyCRMLogin/MyCRMLogin';

const PasswordResetLanding = (props) => {
  const {
    location,
    requestLogin,
    fullPageSpinnerLoading,
    trackResetPasswordAttempt,
    trackResetPasswordSuccess,
  } = props;
  const { token: recoveryToken } = (location && location.query) || {};

  useEffect(() => {
    trackResetPasswordAttempt(recoveryToken);
  }, [recoveryToken]);

  const handleSubmit = () => {
    requestLogin({ intent: 'resetPassword' });
    trackResetPasswordSuccess(recoveryToken);
  };

  return (
    <MyCRMLogin
      headingTitle='Reset your password'
      handleSubmit={handleSubmit}
      centeredTitle
      showFooter
      recoveryToken={recoveryToken}
      fullPageSpinnerLoading={fullPageSpinnerLoading}
    />
  );
};

PasswordResetLanding.propTypes = {
  location: PropTypes.object,
  requestLogin: PropTypes.func.isRequired,
  fullPageSpinnerLoading: PropTypes.bool,
  trackResetPasswordAttempt: PropTypes.func,
  trackResetPasswordSuccess: PropTypes.func,
};

const mapStateToProps = (state) => ({
  fullPageSpinnerLoading: state.UISettings.fullPageSpinnerLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin: scenarioActions.requestLogin,
      trackResetPasswordAttempt: scenarioActions.trackResetPasswordAttempt,
      trackResetPasswordSuccess: scenarioActions.trackResetPasswordSuccess,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetLanding);
