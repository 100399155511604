import React from 'react';
import { ModalProvider } from '../../contexts/ModalContext';
import DataCollectionBankStatement from './DataCollectionBankStatement';

const DataCollectionBankStatementWrapper = () => {
  return (
    <ModalProvider>
      <DataCollectionBankStatement />
    </ModalProvider>
  );
};

export default DataCollectionBankStatementWrapper;
