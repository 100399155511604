/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { OWNER_OCCUPIED_OPTIONS, FREQUENCY_OPTIONS } from 'constants/options';
import { WEEKLY } from 'shared/constants/options';
import { NO_ERROR } from 'constants/validators';

import { generateUniqueID } from 'lib/utils/stringUtils';

import Question from 'components/Question/Question';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';

import findLocality from 'services/localitiesApi';
import findLender from 'services/lendersApi';

import { questionPropTypes } from 'hocs/manageQuestions';

import scenarioActions from 'actions/scenarioActions';
import locale from 'config/locale';

import styles from './Property.css';

class Property extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    title: PropTypes.string,
    setPropertyLocality: PropTypes.func.isRequired,
    setPropertyValue: PropTypes.func.isRequired,
    setPropertyCurrentLender: PropTypes.func.isRequired,
    setPropertyCurrentInterestRate: PropTypes.func.isRequired,
    setPropertyMortgageAmount: PropTypes.func.isRequired,
    setPropertyOwnerOccupied: PropTypes.func.isRequired,
    setPropertyRentalAmount: PropTypes.func.isRequired,
    setPropertyRentalFrequency: PropTypes.func.isRequired,
    setPropertyError: PropTypes.func.isRequired,
    questions: questionPropTypes,
  };

  render() {
    const {
      questions,
      property,
      title,
      setPropertyValue,
      setPropertyMortgageAmount,
      setPropertyRentalAmount,
      setPropertyRentalFrequency,
      setPropertyLocality,
      setPropertyError,
      setPropertyCurrentLender,
      setPropertyCurrentInterestRate,
      setPropertyOwnerOccupied,
    } = this.props;

    return (
      <section
        id={generateUniqueID('Property', property.id)}
        className={styles.root}
      >
        {title && <h4 className={styles.title}>{title}</h4>}

        <Question
          {...questions.locality}
          label='Where is the property?'
          hint={locale.isAU ? 'Enter a Suburb or Postcode' : 'Enter a Suburb'}
        >
          <AutocompleteInput
            setAsyncError={(text) =>
              setPropertyError({ id: 'locality', text, blocking: true })
            }
            removeAsyncError={() =>
              setPropertyError({ id: 'locality', text: NO_ERROR })
            }
            serviceFn={findLocality}
            action={setPropertyLocality}
            value={property.locality}
          />
        </Question>

        <Question
          {...questions.value}
          label='Roughly how much is the property worth?'
        >
          <CurrencyInput action={setPropertyValue} value={property.value} />
        </Question>

        <Question
          {...questions.mortgageAmount}
          label='How much is remaining on the mortgage?'
        >
          <CurrencyInput
            value={property.mortgageAmount}
            action={setPropertyMortgageAmount}
          />
        </Question>
        <Question
          {...questions.currentLender}
          label='Who is your current lender?'
        >
          <AutocompleteInput
            placeholder='Search lenders…'
            serviceFn={findLender}
            action={setPropertyCurrentLender}
            value={property.currentLender}
          />
        </Question>

        <Question
          {...questions.currentInterestRate}
          label='What is your current interest rate?'
        >
          <InputWithSpan
            action={setPropertyCurrentInterestRate}
            value={property.currentInterestRate}
            spanValue='%'
            type='number'
            maxLength={9}
          />
        </Question>

        <Question
          {...questions.ownerOccupied}
          label='Is the property a home or an investment?'
          direction='row'
        >
          <RadioButtonList
            items={OWNER_OCCUPIED_OPTIONS}
            action={setPropertyOwnerOccupied}
            value={property.ownerOccupied}
          />
        </Question>

        <Question
          {...questions.rentalAmount}
          label='How much do you think you could rent it out for?'
        >
          <CurrencyInputWithSelection
            action={setPropertyRentalAmount}
            value={property.rentalAmount}
            selectionDefault={WEEKLY}
            selectionValue={property.rentalFrequency}
            selectionAction={setPropertyRentalFrequency}
            selectionItems={FREQUENCY_OPTIONS}
          />
        </Question>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      setPropertyLocality: scenarioActions.setPropertyLocality(
        ownProps.property.id,
      ),
      setPropertyValue: scenarioActions.setPropertyValue(ownProps.property.id),
      setPropertyMortgageAmount: scenarioActions.setPropertyMortgageAmount(
        ownProps.property.id,
      ),
      setPropertyOwnerOccupied: scenarioActions.setPropertyOwnerOccupied(
        ownProps.property.id,
      ),
      setPropertyIntendsToSell: scenarioActions.setPropertyIntendsToSell(
        ownProps.property.id,
      ),
      setPropertyRentalAmount: scenarioActions.setPropertyRentalAmount(
        ownProps.property.id,
      ),
      setPropertyRentalFrequency: scenarioActions.setPropertyRentalFrequency(
        ownProps.property.id,
      ),
      setPropertyCurrentLender: scenarioActions.setPropertyCurrentLender(
        ownProps.property.id,
      ),
      setPropertyCurrentInterestRate: scenarioActions.setPropertyCurrentInterestRate(
        ownProps.property.id,
      ),
      setPropertyError: scenarioActions.setPropertyError(ownProps.property.id),
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Property);
