import DollarIcon from 'assets/icons/dollar.svg';
import EnergyInverseIcon from 'assets/icons/energy-inverse.svg';
import EnergyIcon from 'assets/icons/energy.svg';
import HeartIcon from 'assets/icons/heart.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import SVGInline from 'react-svg-inline';
import styles from './DataCollectionStepper.css';
import { DATA_COLLECTION_PROCESS } from 'constants/dataCollection';

const STEPS = [
  {
    key: DATA_COLLECTION_PROCESS.Scantek,
    label: 'Identity verification',
    icon: <SVGInline svg={EnergyIcon} />,
    inverseIcon: <SVGInline svg={EnergyInverseIcon} />,
  },
  {
    key: DATA_COLLECTION_PROCESS.Illion,
    label: 'Connect to your banks',
    icon: <SVGInline svg={DollarIcon} />,
    inverseIcon: <SVGInline svg={DollarIcon} />,
  },
  {
    key: 'factFind',
    label: 'Online fact find',
    icon: <SVGInline svg={HeartIcon} />,
    inverseIcon: <SVGInline svg={HeartIcon} />,
  },
];

const DataCollectionStepper = ({ activeStep, inverse, selectedProcesses }) => {
  const SELECTED_STEPS = STEPS.filter((step) =>
    selectedProcesses.includes(step.key),
  );
  SELECTED_STEPS.push(STEPS[2]);

  return (
    <ol className={styles.container}>
      {SELECTED_STEPS.map((step, index) => {
        const isCurrentStep = activeStep === index;
        const isCompletedStep = activeStep > index;
        return (
          <li
            key={step.key}
            className={classNames({
              [styles.activeStep]: isCurrentStep && !inverse,
              [styles.activeStepInverse]: isCurrentStep && inverse,
              [styles.completedStep]: isCompletedStep && !inverse,
              [styles.completedStepInverse]: isCompletedStep && inverse,
            })}
          >
            {inverse ? step.inverseIcon : step.icon}
            <div
              className={classNames(styles.label, {
                [styles.hidden]: activeStep < index,
              })}
              id='label'
            >
              {isCompletedStep ? 'Complete' : step.label}
            </div>
            <div
              className={classNames(styles.line, {
                [styles.activeLine]: activeStep >= index,
                [styles.lineInverse]: inverse,
              })}
              aria-hidden='true'
            />
          </li>
        );
      })}
    </ol>
  );
};

DataCollectionStepper.propTypes = {
  activeStep: PropTypes.number,
  inverse: PropTypes.bool,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

DataCollectionStepper.defaultProps = {
  activeStep: 0,
};

export default DataCollectionStepper;
