import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import _ from 'lodash';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { push } from '@loan_market/react-router-redux-multi';

import { VARIABLE } from 'shared/constants/interestType';
import {
  DEFAULT_LOAN_YEAR,
  DEFAULT_LOAN_AMOUNT,
} from 'shared/constants/defaults';
import {
  unformatCurrency,
  formatNumber,
  formatPercentage as formatPercentageNoIntl,
} from 'shared/lib/numbrero';
import { formatCurrency } from 'lib/intlFormatters';
import {
  formatCurrencyOnChange,
  formatDecimalNumber,
} from 'lib/utils/numberUtils';
import { gotoCompareWithStructure } from 'lib/compareHelper';

import * as interestSavingSelectors from 'selectors/interestSavingSelectors';
import * as fundingSelectors from 'selectors/fundingSelectors';
import * as structureSelectors from 'selectors/structureSelectors';
import * as UISelectors from 'selectors/UISelectors';

import { getCurrentInterestRate } from 'selectors/scenarioSelectors';

import productsActions from 'actions/productsActions';
import structureActions from 'actions/structureActions';

import commonMessages from 'constants/commonMessages';

import LenderRecord from 'components/LenderRecord/LenderRecord';
import SavingTable from 'components/LenderRecord/SavingTable';
import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import Question from 'components/Question/Question';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';
import Button from 'components/Button/Button';

const messages = defineMessages({
  title: {
    id: 'InterestSaving.title',
    defaultMessage: 'Compare potential interest savings',
  },
  loanAmountLabel: {
    id: 'InterestSaving.loanAmountLabel',
    defaultMessage: 'Total loan amount',
  },
  currentRateLabel: {
    id: 'InterestSaving.currentRateLabel',
    defaultMessage: 'Your current loan rate',
  },
  legendInterestSaving: {
    id: 'InterestSaving.legendInterestSaving',
    defaultMessage: 'Pot.Saving',
  },
  initial: {
    id: 'InterestSaving.Initial',
    defaultMessage: 'Initial',
  },
  saveRate: {
    id: 'InterestSaving.saveRate',
    defaultMessage: 'Save {rate}',
  },
  perMonth: {
    id: 'InterestSaving.perMonth',
    defaultMessage: 'Per month',
  },
  lifetimeInterestSaving: {
    id: 'InterestSaving.lifetimeInterestSaving',
    defaultMessage: 'Lifetime interest savings*',
  },
  lifetimeInterestSavingFixedDescription: {
    id: 'InterestSaving.lifetimeInterestSavingFixedDescription',
    defaultMessage:
      '* A total lifetime interest saving of {totalSaving} is possible by reducing your current rate to a fixed rate of {fixedRate} for {fixedTerm} and reverting to a variable rate of {variableRate} - this is based on repaying a loan of {loanAmount} over a {loanTerm}-year term. Note that this does not take into account exit, upfront and ongoing fees, or any possible changes to interest rates in the future.',
  },
  lifetimeInterestSavingVariableDescription: {
    id: 'InterestSaving.lifetimeInterestSavingVariableDescription',
    defaultMessage:
      '* A total lifetime interest saving of {totalSaving} is possible by reducing your current rate to a variable rate of {variableRate} - this is based on repaying a loan of {loanAmount} over a {loanTerm}-year term. Note that this does not take into account exit, upfront and ongoing fees, or any possible changes to interest rates in the future.',
  },
  maxBorrowHeading: {
    id: 'InterestSaving.maxBorrowHeading',
    defaultMessage: 'Maximum Borrowing Capacity',
  },
  potentialSavings: {
    id: 'InterestSaving.potentialSavings',
    defaultMessage: 'Potential Savings',
  },
  overTermYears: {
    id: 'InterestSaving.overTermYears',
    defaultMessage: 'Over {term} {term, plural, one {Year} other {Years}}',
  },
  termYears: {
    id: 'InterestSaving.termYears',
    defaultMessage: '{term, plural, =1 {# year} other {# years}}',
  },
});

export class InterestSaving extends Component {
  static propTypes = {
    interestSavingProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
    maxInterestSaving: PropTypes.number.isRequired,
    currentInterestRate: PropTypes.number.isRequired,
    mortgageAmount: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
    isSpinnerLoading: PropTypes.bool,
    structure: PropTypes.object,
    primaryStructure: PropTypes.object,
    requestRefinanceProducts: PropTypes.func.isRequired,
    updateWorkingStructure: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    loadStructure: PropTypes.func.isRequired,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const { currentInterestRate, mortgageAmount } = props;

    this.state = {
      filters: {
        currentInterestRate,
        mortgageAmount,
      },
    };
  }

  componentDidMount() {
    const { filters } = this.state;
    const {
      requestRefinanceProducts,
      structure,
      primaryStructure,
      loadStructure,
    } = this.props;

    requestRefinanceProducts(filters);

    // Make sure current structure is loaded if any
    if (primaryStructure && !structure.id) {
      loadStructure(primaryStructure.id);
    }

    window.scrollTo(0, 0);
  }

  onChangeFilterHandler = (key, formatter = _.identity) => (value) => {
    const { filters } = this.state;

    filters[key] = formatter(value);
    this.setState({ filters });
  };

  getInterestSavings = () => {
    const { filters } = this.state;
    const {
      currentInterestRate,
      mortgageAmount,
      requestRefinanceProducts,
    } = this.props;

    if (
      (filters.currentInterestRate &&
        _.isFinite(filters.currentInterestRate) &&
        filters.currentInterestRate !== currentInterestRate) ||
      (filters.mortgageAmount &&
        _.isFinite(filters.mortgageAmount) &&
        filters.mortgageAmount !== mortgageAmount)
    ) {
      requestRefinanceProducts(filters);
    }
  };

  gotoLendersProducts = (lenderId) => () => {
    const { updateWorkingStructure, goTo } = this.props;

    gotoCompareWithStructure({
      lenderId,
      goTo,
      updateWorkingStructure,
    });
  };

  updateWorkingStructure = (payload) => {
    this.props.updateWorkingStructure({
      ...payload,
      loanAmount: this.state.filters.mortgageAmount,
    });
  };

  renderSavingRecord = (savingRecord) => {
    const {
      maxInterestSaving,
      intl,
      intl: { formatMessage },
    } = this.props;
    const { currentInterestRate, mortgageAmount } = this.state.filters;
    const {
      maxSaving,
      type,
      minInitialRate,
      minOngoingRate,
      minInitialPeriodInYears,
      bank,
      lender,
      products,
    } = savingRecord;

    const savingPerMonth = maxSaving > 0 ? maxSaving : 0;
    const totalSaving = savingPerMonth * 12 * DEFAULT_LOAN_YEAR;
    const maxTotalSaving = maxInterestSaving * 12 * DEFAULT_LOAN_YEAR;

    const interestDescription =
      type === VARIABLE
        ? formatMessage(messages.lifetimeInterestSavingVariableDescription, {
            totalSaving: formatCurrency(intl)(totalSaving),
            loanAmount: formatCurrency(intl)(mortgageAmount),
            loanTerm: DEFAULT_LOAN_YEAR,
            variableRate: formatPercentageNoIntl(minInitialRate),
          })
        : formatMessage(messages.lifetimeInterestSavingFixedDescription, {
            totalSaving: formatCurrency(intl)(totalSaving),
            loanAmount: formatCurrency(intl)(mortgageAmount),
            loanTerm: DEFAULT_LOAN_YEAR,
            variableRate: formatPercentageNoIntl(minOngoingRate),
            fixedRate: formatPercentageNoIntl(minInitialRate),
            fixedTerm: formatMessage(messages.termYears, {
              term: minInitialPeriodInYears,
            }),
          });

    return (
      bank && (
        <LenderRecord
          key={lender.id}
          lender={savingRecord}
          products={products}
          primaryAction={this.gotoLendersProducts(bank.lenderId)}
          maximumValue={maxTotalSaving}
          value={totalSaving}
          legend={formatMessage(messages.legendInterestSaving)}
          updateWorkingStructure={this.updateWorkingStructure}
          currentLoanRate={currentInterestRate}
          productTable={SavingTable}
          productTableProps={{
            currentInterestRate,
          }}
          infoSectionProps={{
            heading: formatMessage(messages.lifetimeInterestSaving),
            headingInfoItems: [interestDescription],
            valueHeading: formatMessage(messages.potentialSavings),
            valueSubtitle: formatMessage(messages.overTermYears, {
              term: DEFAULT_LOAN_YEAR,
            }),
          }}
        />
      )
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  renderBackButton = (text) => {
    return (
      <Button
        id='backButton'
        icon='mi-arrow-left'
        theme='backButtonTheme'
        onClick={this.goBack}
      >
        {text}
      </Button>
    );
  };

  renderQuestions = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { currentInterestRate, mortgageAmount } = this.state.filters;

    return (
      <div className='rowContainer'>
        <Question
          id='loanAmount'
          label={formatMessage(messages.loanAmountLabel)}
          className='interestSaving'
        >
          <CurrencyInput
            value={mortgageAmount}
            action={this.onChangeFilterHandler(
              'mortgageAmount',
              unformatCurrency,
            )}
            onChange={formatCurrencyOnChange}
            onBlur={this.getInterestSavings}
          />
        </Question>
        <Question
          id='currentInterestRate'
          label={formatMessage(messages.currentRateLabel)}
          className='interestSaving'
        >
          <InputWithSpan
            value={currentInterestRate}
            spanValue='%'
            action={this.onChangeFilterHandler(
              'currentInterestRate',
              formatDecimalNumber,
            )}
            onBlur={this.getInterestSavings}
            onChange={formatNumber}
            type='number'
            maxLength={9}
          />
        </Question>
      </div>
    );
  };

  render() {
    const {
      isSpinnerLoading,
      intl: { formatMessage },
      interestSavingProducts,
    } = this.props;
    const { currentInterestRate, mortgageAmount } = this.state.filters;

    return (
      <View>
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='interestSaving'
            title={formatMessage(messages.title)}
            animateHeading={false}
            isForm
            formCompleted={!!currentInterestRate && !!mortgageAmount}
            onSubmit={this.getInterestSavings}
            onFocusLost={this.getInterestSavings}
          >
            {this.renderBackButton(formatMessage(commonMessages.back))}
            {this.renderQuestions()}
            {interestSavingProducts.map((savingRecord) =>
              this.renderSavingRecord(savingRecord),
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  interestSavingProducts: interestSavingSelectors.getInterestProductDetails(
    state,
  ),
  maxInterestSaving: interestSavingSelectors.getMaxInterestSaving(state),
  currentInterestRate: getCurrentInterestRate(state),
  mortgageAmount:
    fundingSelectors.totalLoanRequired(state) || DEFAULT_LOAN_AMOUNT,
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  structure: structureSelectors.workingStructure(state),
  primaryStructure: structureSelectors.primaryStructure(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestRefinanceProducts: productsActions.requestRefinanceProducts,
      updateWorkingStructure: structureActions.updateWorkingStructure,
      loadStructure: structureActions.loadStructure,
      goTo: push,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(InterestSaving);
