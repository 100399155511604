import React from 'react';
import WhatToExpect from './WhatToExpect';
import GetStarted from './GetStarted';
import { useHistory } from 'react-router-dom';
import { DATA_COLLECTION_LANDING_PATH } from 'shared/constants/paths';
import PropTypes from 'prop-types';

const GET_STARTED = 'get-started';
const WHAT_TO_EXPECT = 'what-to-expect';
const DataCollectionLandingContent = (props) => {
  const history = useHistory();
  const currentStep = history.location.query.step
    ? history.location.query.step
    : GET_STARTED;
  const handleSetGetStarted = () => {
    history.push(`${DATA_COLLECTION_LANDING_PATH}?step=${WHAT_TO_EXPECT}`);
  };

  const isStepGetStarted = currentStep === GET_STARTED;
  return isStepGetStarted ? (
    <GetStarted setGetStarted={handleSetGetStarted} {...props} />
  ) : (
    <WhatToExpect {...props} />
  );
};

DataCollectionLandingContent.propTypes = {
  name: PropTypes.string,
  onLogoutClick: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};
export default DataCollectionLandingContent;
