import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OktaSignIn from '@okta/okta-signin-widget';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import BackgroundColorWrapper from 'components/BackgroundColorWrapper/BackgroundColorWrapper';

import scenarioActions from 'actions/scenarioActions';

import { getSignInConfig } from 'lib/okta';
import LocalStorageProxy from 'lib/localStorageProxy';

import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import './WidgetStyle.nonmodule.css';
import { removeChatWidget } from 'lib/prepareStore';

export const MyCRMLogin = (props) => {
  const {
    headingTitle,
    headingIcon,
    handleSubmit,
    centeredTitle,
    showFooter,
    recoveryToken,
    stateToken,
    fullPageSpinnerLoading,
    trackPasswordCapture,
    trackMobileCapture,
    trackLoginAttempt,
    trackOktaSmsAuthVerify,
    trackOktaGoogleAuthVerify,
    trackOktaLoginSuccess,
    mobileNumberCountryCode,
  } = props;
  const [altTitle, setAltTitle] = useState('');
  const [oktaEmail, setOktaEmail] = useState('');
  const [controller, setController] = useState('');
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const widgetRef = useRef();

  useEffect(() => {
    if (oktaEmail && controller === 'mfa-verify') {
      const smsSendBtn = document.querySelectorAll('.sms-request-button')[0];
      if (smsSendBtn) {
        trackOktaSmsAuthVerify(oktaEmail);
      } else {
        trackOktaGoogleAuthVerify(oktaEmail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller, oktaEmail]);

  const handleWidgetEvents = (context) => {
    document
      .querySelectorAll(
        '#sign-in-widget .button.button-primary:not([data-se="sms-request-button"])',
      )
      .forEach((btn) => {
        btn.classList.add('brandColor__button', 'Button__base');
        btn.classList.remove('button', 'button-primary');
      });
    if (typeof context.controller === 'function') {
      trackMobileCapture();
      setAltTitle('Let’s log you in securely');
    } else {
      setController(context.controller);
    }
    switch (context.controller) {
      case 'password-reset':
        var savePasswordBtn = document.querySelectorAll('.button-primary')[0];
        savePasswordBtn &&
          savePasswordBtn.addEventListener('click', () => {
            trackPasswordCapture();
          });
        break;
      case 'primary-auth':
        var submitBtn = document.querySelector('#okta-signin-submit');
        var emailIpt = document.querySelector('#okta-signin-username');
        submitBtn.addEventListener('click', () => {
          trackLoginAttempt(emailIpt?.value);
          setOktaEmail(emailIpt?.value);
        });
        break;
      case 'enroll-choices':
        setAltTitle('Select a security backup method');
        break;
    }
  };

  useEffect(() => {
    if (fullPageSpinnerLoading !== false || !widgetRef.current) {
      return;
    }

    const signInConfig = getSignInConfig(
      history,
      recoveryToken,
      stateToken,
      mobileNumberCountryCode,
    );

    const widget = new OktaSignIn(signInConfig);
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(async (tokens) => {
        await oktaAuth.tokenManager.setTokens(tokens);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });

    widget.on('afterRender', handleWidgetEvents);

    oktaAuth.authStateManager.subscribe(async (authState) => {
      if (authState.isAuthenticated) {
        removeChatWidget();
        LocalStorageProxy.token = authState.accessToken?.accessToken;
        headingTitle?.includes('Log in') &&
          trackOktaLoginSuccess(authState.oktaUser);
        const origUrl = oktaAuth.getOriginalUri();
        handleSubmit(origUrl);
      }
    });

    return () => {
      oktaAuth.authStateManager.unsubscribe();
      return widget.remove();
    };
  }, [fullPageSpinnerLoading]);

  return (
    <BackgroundColorWrapper>
      <View inverse showFooter={showFooter}>
        <ContentsWrapper
          id='emailPasswordLogin'
          title={altTitle || headingTitle}
          headingIcon={headingIcon}
          inverseHeader
          className='halfwidth'
          centeredTitle={centeredTitle}
        >
          <div ref={widgetRef} id='sign-in-widget' />
        </ContentsWrapper>
      </View>
    </BackgroundColorWrapper>
  );
};

MyCRMLogin.propTypes = {
  headingTitle: PropTypes.string,
  headingIcon: PropTypes.node,
  handleSubmit: PropTypes.func,
  centeredTitle: PropTypes.bool,
  showFooter: PropTypes.bool,
  recoveryToken: PropTypes.string,
  stateToken: PropTypes.string,
  trackPasswordCapture: PropTypes.func,
  trackMobileCapture: PropTypes.func,
  trackLoginAttempt: PropTypes.func,
  trackOktaSmsAuthVerify: PropTypes.func,
  trackOktaGoogleAuthVerify: PropTypes.func,
  trackOktaLoginSuccess: PropTypes.func,
  mobileNumberCountryCode: PropTypes.string,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackPasswordCapture: scenarioActions.trackPasswordCapture,
      trackMobileCapture: scenarioActions.trackMobileCapture,
      trackLoginAttempt: scenarioActions.trackLoginAttempt,
      trackOktaSmsAuthVerify: scenarioActions.trackOktaSmsAuthVerify,
      trackOktaGoogleAuthVerify: scenarioActions.trackOktaGoogleAuthVerify,
      trackOktaLoginSuccess: scenarioActions.trackOktaLoginSuccess,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(MyCRMLogin);
