export const ROOT_PATH = '/';
export const ABOUT_PROPERTY_PATH = '/about-the-property';
export const BANKING_PREFERENCES_PATH = '/banking-preferences';
export const BUYER_SCENARIOS_PATH = '/buyer-scenarios';
export const CASH_FLOW_REPORT_PATH = '/cash-flow-report';
export const PROPERTY_RENT_PATH = '/property-rent';
export const CONTACT_DETAILS_PATH = '/contact-details';
export const COMPARE_PAGE_PATH = '/compare-page';
export const EXISTING_PROPERTY_PATH = '/existing-property';
export const FIRST_HOME_OWNERS_GRANT_PATH = '/first-home-owners-grant';
export const FIRST_HOME_OWNERS_RESULT_PATH = '/first-home-owners-result';
export const GET_STARTED_PATH = '/get-started';
export const LOAN_REPORT_PATH = '/loan-report';
export const REFINANCING_DETAILS_PATH = '/refinancing-details';
export const REASON_FOR_REFINANCE_PATH = '/reason-for-refinance';
export const REFINANCE_PROPERTY_PATH = '/refinance-property';
export const SALE_REPORT_PATH = '/sale-report';
export const STAMP_DUTY_PATH = '/stamp-duty';
export const SIGN_UP_PATH = '/sign-up';
export const PRODUCT_DETAIL_PATH = '/product-detail';
export const LOGIN_CAPTURE_PATH = '/login';
export const LOGIN_FOR_SIGNUP_PATH = '/login-signup';
export const BROKER_LOGIN_PATH = '/broker-login';
export const DASHBOARD_BASE_PATH = '/dashboard';
export const PREFERENCES_PATH = '/preferences';
export const APPLY_BASE_PATH = '/apply';
export const BORROWING_CAPACITY_PATH = '/borrowing-capacity';
export const APPLICATION_PROGRESS_BASE_PATH = '/progress';
export const APPLICATION_PROGRESS_PATH = '/progress/:loanApplicationId';
export const INTEREST_SAVING_PATH = '/interest-saving';
export const UNSUBSCRIBE_PATH = '/unsubscribe';
export const NOT_FOUND_PATH = '/not-found';
export const HELP_PATH = '/help';
export const HELP_SHARED_LOGIN_PATH = '/help/shared-login';
export const HELP_EXPIRED_SESSION_PATH = '/help/expired-session';
export const HELP_INVALID_PRIVACY_POLICY_PATH = '/help/invalid-privacy-policy';
export const HELP_ALREADY_EXECUTED_PRIVACY_POLICY_PATH =
  '/help/already-executed-privacy-policy';
export const EMAIL_VERIFICATION_PATH = '/email-verification';
export const PASSWORD_RECOVERY_PATH = '/password-recovery';
export const PASSWORD_RESET_PATH = '/reset-password';
export const PRIVACY_POLICY_PATH = '/privacy-policy';
export const NEXT_STEPS_PATH = '/next-steps';

/* Goal Setter */
export const GOALS_PATH = '/goals';

export const CURRENT_BANK = 'current-bank';
export const CURRENT_BANK_PATH = `${PREFERENCES_PATH}/${CURRENT_BANK}`;
export const PREFERRED_LENDERS = 'lenders';
export const PREFERRED_LENDERS_PATH = `${PREFERENCES_PATH}/${PREFERRED_LENDERS}`;
export const PREFERRED_LOAN_OPTIONS = 'loan-options';
export const PREFERRED_LOAN_OPTIONS_PATH = `${PREFERENCES_PATH}/${PREFERRED_LOAN_OPTIONS}`;
export const CLIENTS_PATH = '/clients';
export const SERVICEABILITY_PATH = '/serviceability';
export const LOAN_AMOUNT_PATH = '/loan-amount';
export const LOAN_STRUCTURES_PATH = '/loan-structures';
export const FUTURE_PATH = '/future';
export const TEAM_PATH = '/team';
export const SERVICES_PATH = '/services';
export const LONG_TERM = 'long-term-goals';
export const LONG_TERM_GOALS_CONSOLIDATED = 'long-term-goals-consolidated';
export const RETIREMENT = 'retirement';
export const HORIZON = 'horizon';
export const LONG_TERM_PATH = `${FUTURE_PATH}/${LONG_TERM}`;
export const RETIREMENT_PATH = `${FUTURE_PATH}/${RETIREMENT}`;
export const HORIZON_PATH = `${FUTURE_PATH}/${HORIZON}`;

export const DATA_COLLECTION_LANDING_PATH = '/data-collection-landing';
export const DATA_COLLECTION_ID_VERIFICATION_PATH = '/data-collection-verify';
export const DATA_COLLECTION_ID_MANUAL_VERIFICATION_PATH =
  '/data-collection-verify-manual';
export const DATA_COLLECTION_BANK_STATEMENT_PATH = '/data-collection-statement';
export const DATA_COLLECTION_STATUS_POLLING_PATH =
  '/data-collection-status-poll';
export const DATA_COLLECTION_CREDIT_CHECK_PATH =
  '/data-collection-verify-address';

export const NZ_BRANDED_EXCLUDE_REDIRECT = [
  BROKER_LOGIN_PATH,
  HELP_PATH,
  HELP_SHARED_LOGIN_PATH,
  HELP_EXPIRED_SESSION_PATH,
  LOGIN_CAPTURE_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_RECOVERY_PATH,
  EMAIL_VERIFICATION_PATH,
];

export const UPDATE_LOGIN_POPUP_PATHS = [
  '/applicant',
  '/client/:id',
  '/coapplicant/:id',
];

export const CONTEXT_PAGE_PATH = '/context';
