import React, { Component } from 'react';
import { defineMessages, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  PROGRESS_STATUS_COMPLETE,
  PROGRESS_STATUS_IN_PROGRESS,
  PROGRESS_STATUS_NEXT_STEP,
  PROGRESS_STATUS_NOT_STARTED_YET,
} from 'constants/applicationProgress';

import styles from './ProgressItem.css';

const messages = defineMessages({
  completed: {
    id: 'progressItem.completed',
    defaultMessage: 'Completed',
  },
  inProgress: {
    id: 'progressItem.inProgress',
    defaultMessage: 'In progress',
  },
  nextStep: {
    id: 'progressItem.nextStep',
    defaultMessage: 'Next step',
  },
  notStartedYet: {
    id: 'progressItem.notStartedYet',
    defaultMessage: 'Not started yet',
  },
});

class ProgressItem extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    status: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.node,
  };

  static statusIcons = {
    [PROGRESS_STATUS_COMPLETE]: 'sl-custom-chat-1-1',
    [PROGRESS_STATUS_IN_PROGRESS]: 'sl-custom-play-2',
    [PROGRESS_STATUS_NEXT_STEP]: 'sl-custom-next-1-1',
    [PROGRESS_STATUS_NOT_STARTED_YET]: 'sl-custom-power-1-1',
  };

  renderSummary() {
    const {
      intl: { formatMessage },
      icon,
      status,
      title,
      subtitle,
    } = this.props;

    const iconStyle = classNames(styles.icon, icon);
    return (
      <div className={styles.summary}>
        <div className={styles.iconAndHeaderSection}>
          <div className={iconStyle} />
          <div className={styles.textSection}>
            <h4>{title}</h4>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        </div>
        <div className={`${styles.statusSection} ${styles.completed}`}>
          <span className={styles.completeText}>
            {formatMessage(messages[status])}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { children, status } = this.props;
    const rootStyles = classNames(styles.root, styles[status]);
    return (
      <div className={rootStyles}>
        {this.renderSummary()}
        {status !== PROGRESS_STATUS_COMPLETE && (
          <div className={styles.description}>{children}</div>
        )}
      </div>
    );
  }
}

export default ProgressItem;
