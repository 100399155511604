import InfoAlertIcon from 'assets/icons/info-alert.svg';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import Button from 'components/Button/Button';
import { DataCollectionPageSection } from 'components/DataCollectionPageSection/DataCollectionPageSection';
import DataCollectionStepper from 'components/DataCollectionStepper/DataCollectionStepper';
import DateSelector from 'components/DateSelector/DateSelector';
import Form from 'components/Form/Form';
import Input from 'components/Input/Input';
import Question from 'components/Question/Question';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import SVGInline from 'react-svg-inline';
import styles from './DataCollectionManualVerifyIdForm.css';
import {
  DATA_COLLECTION_EVENT,
  DATA_COLLECTION_PROCESS,
} from 'constants/dataCollection';
import { postDataCollectionProcessEvent } from 'services/dataCollectionApi';

const Alert = () => {
  return (
    <div className={styles.alert}>
      <SVGInline svg={InfoAlertIcon} />
      <p>Please ensure the details below are accurate before proceeding.</p>
    </div>
  );
};

const DataCollectionManualVerifyIdForm = ({
  onSubmit,
  applicationId,
  clientId,
  firstName: firstNameInit,
  middleName: middleNameInit,
  lastName: lastNameInit,
  dob,
  address: addressInit,
  selectedProcesses,
}) => {
  const [firstName, setFirstName] = useState(firstNameInit);
  const [middleName, setMiddleName] = useState(middleNameInit);
  const [lastName, setLastName] = useState(lastNameInit);
  const [dobDay, setDobDay] = useState(dob?.day);
  const [dobMonth, setDobMonth] = useState(dob?.month);
  const [dobYear, setDobYear] = useState(dob?.year);
  const [licenseNumber, setLicenseNumber] = useState();
  const [address, setAddress] = useState(addressInit);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [forceFocus, setForceFocus] = useState('');

  useEffect(() => {
    const skipScantek = async () => {
      await postDataCollectionProcessEvent(
        applicationId,
        DATA_COLLECTION_PROCESS.Scantek,
        DATA_COLLECTION_EVENT.Skip,
      );
    };
    skipScantek();
  }, [applicationId]);

  const handleNext = async () => {
    setSubmitClicked(true);
    if (
      !firstName ||
      !lastName ||
      !dobYear ||
      !dobMonth ||
      !dobDay ||
      !licenseNumber
    ) {
      if (!firstName) {
        setForceFocus('firstName');
      } else if (!lastName) {
        setForceFocus('lastName');
      } else if (!dobYear || !dobMonth || !dobDay) {
        setForceFocus('dob');
      } else if (!licenseNumber) {
        setForceFocus('licenseNumber');
      } else {
        setForceFocus('address');
      }

      return;
    }
    onSubmit({
      loanScenarioId: applicationId,
      clientId,
      firstName,
      middleName,
      lastName,
      dateOfBirth: new Date(`${dobYear}-${dobMonth}-${dobDay}`).toISOString(),
      address: address?.formattedAddress || address?.text,
      driversLicense: licenseNumber,
    });
    await postDataCollectionProcessEvent(
      applicationId,
      DATA_COLLECTION_PROCESS.ManuelIdVerify,
      DATA_COLLECTION_EVENT.Begin,
    );
  };

  return (
    <DataCollectionPageSection id='dataCollectionIDVerification'>
      <DataCollectionStepper
        activeStep={0}
        selectedProcesses={selectedProcesses}
      />
      <h1>Verify your identity</h1>
      <p className={styles.lighterText}>
        Your personal details including identification are collected to help us
        to verify that it&apos;s really you and protect your data
      </p>
      <div className={styles.card}>
        <Alert />
        <Form>
          <fieldset>
            <legend>What is your full legal name as shown on your ID</legend>
            <Question
              id='firstName'
              key='question.firstName'
              label='First name'
              className='fullWidthInput'
              error='Please provide an answer'
              showError={submitClicked && !firstName}
            >
              <Input
                action={setFirstName}
                value={firstName}
                forceFocus={forceFocus === 'firstName'}
              />
            </Question>
            <Question
              id='middleName'
              key='question.middleName'
              label='Middle name'
              className='fullWidthInput'
            >
              <Input action={setMiddleName} value={middleName} />
            </Question>
            <Question
              id='lastName'
              key='question.lastName'
              label='Last name'
              className='fullWidthInput'
              error='Please provide an answer'
              showError={submitClicked && !lastName}
            >
              <Input
                action={setLastName}
                value={lastName}
                forceFocus={forceFocus === 'lastName'}
              />
            </Question>
          </fieldset>
          <Question
            id='dob'
            key='question.dob'
            label='Date of birth'
            className='fullWidthInput'
            error='Please provide an answer'
            showError={submitClicked && (!dobDay || !dobMonth || !dobYear)}
          >
            <DateSelector
              type='date'
              dayAction={setDobDay}
              day={dobDay}
              monthAction={setDobMonth}
              month={dobMonth}
              yearAction={setDobYear}
              year={dobYear}
              maxDate={moment()}
              yearsOrder='descending'
              forceFocus={forceFocus === 'dob'}
            />
          </Question>
        </Form>

        <Question
          id='licenseNumber'
          key='question.licenseNumber'
          label="Driver's licence number (unexpired)"
          className='fullWidthInput'
          error='Please provide an answer'
          showError={submitClicked && !licenseNumber}
        >
          <Input
            action={setLicenseNumber}
            value={licenseNumber}
            forceFocus={forceFocus === 'licenseNumber'}
          />
        </Question>
        <Question
          id='currentAddress'
          key='question.currentAddress'
          label='Current residential address'
          className='fullWidthInput'
          error='Please select an item from the suggestions provided'
          showError={submitClicked && !address}
        >
          <div>
            <AutocompleteAddress
              setError={() => {}}
              action={setAddress}
              intlOnChange={setAddress}
              addressId='currentAddress'
              value={address?.formattedAddress || address?.text}
              intlValue={address}
              forceFocus={forceFocus === 'address'}
            />
          </div>
        </Question>
      </div>
      <div className={styles.buttonGroup}>
        <Button
          onClick={handleNext}
          theme='buttonNext'
          className={styles.blockBtn}
        >
          Next step
        </Button>
      </div>
    </DataCollectionPageSection>
  );
};

DataCollectionManualVerifyIdForm.propTypes = {
  onSubmit: PropTypes.func,
  applicationId: PropTypes.number,
  clientId: PropTypes.number,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dob: PropTypes.object,
  address: PropTypes.object,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

export default DataCollectionManualVerifyIdForm;
