import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import UIActions from 'actions/UIActions';
import DataCollectionBankStatementFrame from 'components/DataCollectionBankStatementFrame/DataCollectionBankStatementFrame';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import { injectIntl } from 'react-intl';

import { useModal } from '../../contexts/ModalContext';
import Modal from '../../components/Modal/Modal';

const DataCollectionBankStatement = (props) => {
  const {
    isSpinnerLoading,
    applicationId,
    familyContactId,
    clientId,
    setPageError,
    selectedProcesses,
  } = props;
  const { isModalShown, hideModal, modalConfig } = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isModalShown && <Modal {...modalConfig} hideModal={hideModal} />}
      <View showMenu={false} showHelpFooter>
        <Spinner loading={isSpinnerLoading}>
          <DataCollectionBankStatementFrame
            clientId={clientId}
            familyContactId={familyContactId}
            applicationId={applicationId}
            setPageError={setPageError}
            selectedProcesses={selectedProcesses}
          />
        </Spinner>
      </View>
    </>
  );
};

DataCollectionBankStatement.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  applicationId: PropTypes.number,
  familyContactId: PropTypes.number,
  clientId: PropTypes.number,
  setPageError: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  clientId: clientSelectors.getPrimaryApplicantId(state),
  familyContactId: clientSelectors.primaryApplicantContactId(state),
  applicationId: applicationSelectors.getApplicationId(state),
  selectedProcesses: dataCollectionSelectors.selectedProcesses(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      setPageError: UIActions.setPageError,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataCollectionBankStatement),
);
