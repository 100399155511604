import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UISelectors from 'selectors/UISelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import UIActions from 'actions/UIActions';
import DataCollectionManualVerifyIdForm from 'components/DataCollectionManualVerifyIdForm/DataCollectionManualVerifyIdForm';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import {
  DATA_COLLECTION_EVENT,
  DATA_COLLECTION_PROCESS,
} from 'constants/dataCollection';
import { injectIntl } from 'react-intl';
import {
  postDataCollectionProcessEvent,
  postManualIdentityVerifications,
} from 'services/dataCollectionApi';
import {
  DATA_COLLECTION_BANK_STATEMENT_PATH,
  DATA_COLLECTION_STATUS_POLLING_PATH,
} from 'shared/constants/paths';

const DataCollectionManualIDVerification = ({
  goToPathWithAnimation,
  isSpinnerLoading,
  pushSpinner,
  popSpinner,
  clientId,
  applicationId,
  primaryApplicant,
  currentAddress,
  setPageError,
  selectedProcesses,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextPagePath = selectedProcesses.includes(
    DATA_COLLECTION_PROCESS.Illion,
  )
    ? DATA_COLLECTION_BANK_STATEMENT_PATH
    : DATA_COLLECTION_STATUS_POLLING_PATH;

  const handleSubmit = async (data) => {
    pushSpinner('LOADING');
    try {
      await postDataCollectionProcessEvent(
        applicationId,
        DATA_COLLECTION_PROCESS.ManuelIdVerify,
        DATA_COLLECTION_EVENT.Begin,
      );
      await postManualIdentityVerifications(data);
    } catch (error) {
      setPageError({ status: 500 });
      popSpinner('LOADING');
      return;
    }
    popSpinner('LOADING');
    goToPathWithAnimation({ path: nextPagePath });
  };

  return (
    <View showMenu={false} showHelpFooter>
      <Spinner loading={isSpinnerLoading}>
        <DataCollectionManualVerifyIdForm
          onSubmit={handleSubmit}
          clientId={clientId}
          applicationId={applicationId}
          firstName={primaryApplicant?.firstName}
          middleName={primaryApplicant?.middleName}
          lastName={primaryApplicant?.lastName}
          dob={primaryApplicant?.dob}
          address={currentAddress?.address}
          selectedProcesses={selectedProcesses}
        />
      </Spinner>
    </View>
  );
};

DataCollectionManualIDVerification.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  applicationId: PropTypes.number,
  clientId: PropTypes.number,
  primaryApplicant: PropTypes.object,
  currentAddress: PropTypes.object,
  setPageError: PropTypes.func,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  clientId: clientSelectors.getPrimaryApplicantId(state),
  applicationId: applicationSelectors.getApplicationId(state),
  primaryApplicant: clientSelectors.primaryApplicant(state),
  currentAddress: addressSelectors.primaryCurrentAddress(state),
  selectedProcesses: dataCollectionSelectors.selectedProcesses(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
      setPageError: UIActions.setPageError,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DataCollectionManualIDVerification),
);
