import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import locale from 'config/locale';

import scenarioActions from 'actions/scenarioActions';

import { campaigns } from 'constants/campaigns';
import { getLenderFilterBycountryCode } from 'shared/lib/lenderHelper';

import View from 'components/View/View';
import LogoGrid from 'components/LogoGrid/LogoGrid';

import { GET_STARTED_PATH } from 'shared/constants/paths';
import Divider from 'assets/divider.png';
import Ratings from 'assets/ratings.png';

import { messages, loanPurposes } from './constants';
import styles from 'components/View/View.css';

export class LoanMarketPropertyGoals extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    setLoanPurpose: PropTypes.func.isRequired,
    history: PropTypes.object,
  };

  campaign() {
    const query = this.props.history.query;
    return query && campaigns[query.campaign];
  }

  title() {
    const { intl } = this.props;
    const campaignTitle = this.campaignTitle();

    return campaignTitle || intl.formatMessage(messages.title);
  }

  campaignTitle() {
    const queryTitle = this.props.history.location.query.title;
    let title = queryTitle && decodeURI(queryTitle).replace(/(_)/g, ' ');

    if (!title) {
      const campaign = this.campaign();
      title = campaign && campaign.title;
    }

    if (title) {
      const lastChar = title.charAt(title.length - 1);
      return !['.', '?', '!'].includes(lastChar) ? `${title}.` : title;
    }

    return 'What journey can we help you on today?';
  }

  renderStandardBtn = (text) => {
    const { intl } = this.props;
    return (
      <a href={`#${GET_STARTED_PATH}`} className={styles.link_button}>
        <span>{intl.formatMessage(text)}</span>
      </a>
    );
  };

  renderPurposeBtn = ({ key, url, icon, label, value }) => {
    const { intl, setLoanPurpose } = this.props;
    return (
      <a
        type='button'
        href={`#${url}`}
        key={key}
        onClick={() => setLoanPurpose(value)}
        className={styles.purpose_button}
      >
        <img src={icon} />
        <span>{intl.formatMessage(label)}</span>
      </a>
    );
  };

  render() {
    const { intl } = this.props;
    const lenders = getLenderFilterBycountryCode(locale.data.countryCode);

    return (
      <View theme='landing'>
        <section></section>

        <section>
          <div className={styles.sectionWrapper}>
            <h1 className={styles.header}>
              Loans made <strong>simple.</strong>
            </h1>
            <p className={styles.caption}>{this.campaignTitle()}</p>
          </div>
        </section>

        <section>
          <div className={styles.sectionWrapper}>
            {loanPurposes.map(this.renderPurposeBtn)}
          </div>
        </section>

        <section>
          <div className={styles.sectionWrapper}>
            <h1 className={styles.header}>
              {intl.formatMessage(messages.compareTitle)}
            </h1>
            <p className={styles.caption}>
              We have access to over 60 lenders, including the Big 4.
            </p>
            <LogoGrid lenders={lenders} />
            {this.renderStandardBtn(messages.compareButtonText)}
          </div>
        </section>

        <section>
          <div className={styles.sectionWrapper}>
            <h1 className={styles.header}>
              {intl.formatMessage(messages.negotiateTitle)}
            </h1>
            <p className={styles.caption}>
              {intl.formatMessage(messages.negotiateDescription)}
            </p>
            {this.renderStandardBtn(messages.negotiateButtonText)}
          </div>
        </section>

        <section>
          <div className={styles.sectionWrapper}>
            <h1 className={styles.header}>
              {intl.formatMessage(messages.saveTitle)}
            </h1>
            <p className={styles.caption}>
              {intl.formatMessage(messages.saveDescription)}
            </p>
            {this.renderStandardBtn(messages.saveButtonText)}
          </div>
        </section>

        <section>
          <img src={Divider} />
        </section>

        <section>
          <div className={styles.sectionWrapper}>
            <div>
              <h1 className={styles.header}>
                {intl.formatMessage(messages.ratingTitle)}
              </h1>
              <p className={styles.caption}>
                {intl.formatMessage(messages.ratingDescription)}
              </p>
            </div>
            <img src={Ratings} />
          </div>
          <div className={styles.sectionWrapper}>
            {this.renderStandardBtn(messages.getStartedButtonText)}
          </div>
        </section>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoanPurpose: scenarioActions.setLoanPurpose,
    },
    dispatch,
  );

export default injectIntl(
  connect(() => ({}), mapDispatchToProps)(LoanMarketPropertyGoals),
);
