import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TickTickIcon from 'assets/icons/ticktick.svg';
import PenIcon from 'assets/icons/pen.svg';
import Button from 'components/Button/Button';
import { sanitize } from 'dompurify';
import locale from 'config/locale';

import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';
import * as otherSelectors from 'selectors/otherSelectors';

import loanApplicationActions from 'actions/loanApplicationActions';
import UIActions from 'actions/UIActions';

import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import { injectIntl } from 'react-intl';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import styles from './ContextPage.css';
import SVGInline from 'react-svg-inline';
import { ContextPageType } from 'constants/contextTypes';
import { APPLY_BASE_PATH } from 'shared/constants/paths';
import { getContextPageType, nzContextContentMapper } from 'lib/contextHelper';

const PAGE_TYPE_TO_CONTENT_MAP = {
  [ContextPageType.SMART_DATA_NEW_CLIENT]: {
    heading: `We’ve pre-filled your data. <br/> Complete the rest of your profile.`,
    para1: {
      heading: `Pre-filled data`,
      context: `To save you time, we’ve pre-filled some of your profile based on the data that you’ve provided so far`,
    },
    para2: {
      heading: `Complete the rest of your profile`,
      context: `Fill in the remaining sections in as much detail as you can, then send it to your broker to review`,
    },
  },
  [ContextPageType.SMART_DATA_OLD_DATA]: {
    heading: `Update your existing information & <br />  complete your profile`,
    para1: {
      heading: `Review your information`,
      context: `Some of your data is over 90 days old. You’ll need to review your existing information and ensure it is up to date`,
    },
    para2: {
      heading: `Complete remaining sections in detail`,
      context: `Fill in the rest of your profile in as much detail as you can, then send it to your broker for review`,
    },
  },
  [ContextPageType.NO_SMART_DATA_NEW_CLIENT]: {
    heading: `Complete your profile in detail`,
    para1: {
      heading: `Complete each section`,
      context: `Fill in your profile in as much detail as you can, until each section is marked complete`,
    },
    para2: {
      heading: `Send to broker`,
      context: `Once you’ve completed your profile, send it to your broker so that they can review your information & progress with your loan`,
    },
  },
  [ContextPageType.NO_SMART_DATA_OLD_DATA]: {
    heading: `Review & update your profile`,
    para1: {
      heading: `Review your information`,
      context: `Some of your data is over 90 days old, so you’ll need to review your existing information and ensure it is up to date`,
    },
    para2: {
      heading: `Complete remaining sections in detail`,
      context: `Fill in the rest of your profile in as much detail as you can, then send it to your broker for review`,
    },
  },
};

const ContextPage = ({
  isSpinnerLoading,
  location,
  updateLoanApplication,
  clientId,
  familyId,
  applicationId,
  goToPathWithAnimation,
  isDataCollectionEnabled,
  hasOldInfo,
  primaryApplicant,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let pageType = location.state?.pageType;
  if (!pageType) {
    pageType = getContextPageType(
      isDataCollectionEnabled,
      hasOldInfo,
      primaryApplicant?.hasSeenContext,
    );
  }
  const content = locale.isNZ
    ? nzContextContentMapper(pageType, PAGE_TYPE_TO_CONTENT_MAP[pageType])
    : PAGE_TYPE_TO_CONTENT_MAP[pageType];

  const handleNextClick = () => {
    updateLoanApplication({
      currentFamilyId: familyId,
      currentClientId: clientId,
      hasSeenContext: true,
    });
    goToPathWithAnimation({
      path: `${APPLY_BASE_PATH}/${applicationId}`,
    });
  };

  return (
    <View showMenu={false} showHelpFooter>
      <Spinner loading={isSpinnerLoading}>
        <ContentsWrapper inverseHeader id='contextPage'>
          <section className={styles.container}>
            <h1
              dangerouslySetInnerHTML={{
                __html: sanitize(content.heading || '', {
                  ADD_ATTR: ['target'],
                }),
              }}
            ></h1>
            <div className={styles.contentContainer}>
              <SVGInline svg={TickTickIcon} width='48px' height='48px' />
              <div className={styles.content}>
                <h2>{content.para1.heading}</h2>
                <p>{content.para1.context}</p>
              </div>
            </div>
            <div className={styles.contentContainer}>
              <SVGInline svg={PenIcon} width='48px' height='48px' />
              <div className={styles.content}>
                <h2>{content.para2.heading}</h2>
                <p>{content.para2.context}</p>
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <Button theme='buttonNext' onClick={handleNextClick}>
                Next
              </Button>
            </div>
          </section>
        </ContentsWrapper>
      </Spinner>
    </View>
  );
};

ContextPage.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  updateLoanApplication: PropTypes.func,
  clientId: PropTypes.number,
  familyId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  clientId: clientSelectors.getPrimaryApplicantId(state),
  familyId: clientSelectors.primaryApplicantContactId(state),
  applicationId: applicationSelectors.getApplicationId(state),
  isDataCollectionEnabled: dataCollectionSelectors.isDataCollectionEnabled(
    state,
  ),
  hasOldInfo: otherSelectors.hasOldInfo(state),
  primaryApplicant: clientSelectors.primaryApplicant(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ContextPage),
);
