/**
 * @numberUtils
 * These utils are for
 * functions related to strings and formatting
 */
import _isNaN from 'lodash/isNaN';
import _mapValues from 'lodash/mapValues';
import _flow from 'lodash/flow';
import _isFinite from 'lodash/isFinite';
import { getCountryCallingCode } from 'libphonenumber-js';
import { unformatCurrency, formatNumber } from 'shared/lib/numbrero';

export function parseCurrencyValues(object) {
  return _mapValues(object, _flow([unformatCurrency, Math.round]));
}

export function formatCurrencyOnChange(value) {
  return formatNumber(unformatCurrency(value));
}

export function convertToPercentage(value) {
  return Math.round(value * 10000) / 100;
}

export function percentageFormatter(value) {
  if (value === Infinity || _isNaN(value)) {
    return '0%';
  }
  return `${convertToPercentage(value)}%`;
}

export function percentageUnformatter(value) {
  if (value === '') {
    return undefined;
  }
  return value / 100;
}

export function unformatPhoneNumber(mobile) {
  if (!mobile) {
    return mobile;
  }
  return mobile.replace(/[^\d+]/g, '');
}

export function removeCountryCode(mobile) {
  if (!mobile) {
    return mobile;
  }
  return mobile.replace(/^\+?(\s+)?6(\s+)?(4|1)/g, '').trim() || '';
}

export function sensibleSpacing(mobile) {
  const firstTwo = mobile.slice(0, 2);
  const firstThree = mobile.slice(0, 3);
  const isNZ = firstTwo === '02';

  const prefixLength =
    (isNZ && firstThree === '020') || firstTwo === '04' ? 4 : 3;

  const prefixSet = mobile.slice(0, prefixLength);
  const numProper = mobile.slice(prefixLength);

  const splitIndex = Math.floor(numProper.length / 2);

  const firstSet = splitIndex
    ? ` ${numProper.slice(0, Math.max(0, splitIndex))}`
    : '';
  const lastSet = ` ${numProper.slice(Math.max(0, splitIndex))}`;

  return `${prefixSet}${firstSet}${lastSet}`;
}

export function formatMobile(mobile) {
  if (!mobile) {
    return mobile;
  }
  const sanitized = unformatPhoneNumber(mobile);
  const length = sanitized.length;
  if (length <= 3) {
    return sanitized;
  }

  const noCountryCode = removeCountryCode(sanitized);
  const withFirstZero = `${
    noCountryCode[0] === '0' ? '' : '0'
  }${noCountryCode}`;

  return sensibleSpacing(withFirstZero);
}

export function formatLandline(landline) {
  const sanitized = unformatPhoneNumber(landline);
  const length = sanitized.length;

  if (length !== 9 && length !== 10) {
    return sanitized;
  }

  return length === 10
    ? sanitized.replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
    : `${sanitized.slice(0, 2)} ${sanitized.slice(2, 5)} ${sanitized.slice(
        5,
        9,
      )}`;
}

export function formatPhoneNumber(phoneNumber, countryCode) {
  if (phoneNumber.match(/\D/)) {
    return phoneNumber;
  }

  const firstTwo = phoneNumber.slice(0, 2);
  if (countryCode === 'NZ') {
    return firstTwo === '02'
      ? formatMobile(phoneNumber)
      : formatLandline(phoneNumber);
  }

  return firstTwo === '04'
    ? formatMobile(phoneNumber)
    : formatLandline(phoneNumber);
}

export function transform(value) {
  const valueEndsInDecimal = /^\d+(\.\d+)?$/.test(value);
  return value === '' || valueEndsInDecimal ? _.identity : _.toNumber;
}

export const formatWholeNumber = (str) => {
  if (!str && str !== '0') {
    return str;
  }
  const validatedInput = str.replace(/[^\d.]/g, '');
  if (/^\d+(\.\d*)?$/.test(validatedInput)) {
    return validatedInput;
  }
  return Number(validatedInput);
};

export function formatDecimalNumber(value) {
  const strings = `${value}`.split('.');
  if (strings.length === 1) {
    return formatWholeNumber(strings[0]);
  }
  const right = formatWholeNumber(strings[1]);
  return `${formatWholeNumber(strings[0])}${
    right !== '' || strings[1] === '' ? `.${right}` : ''
  }`;
}

export function pickNumeric(value, alternativeValue) {
  return _isFinite(value) ? value : alternativeValue;
}

export const formatInternationalNumber = (phoneNum, countryCode) => {
  if (
    !phoneNum ||
    typeof phoneNum !== 'string' ||
    !countryCode ||
    phoneNum.startsWith('+')
  ) {
    return phoneNum;
  }
  const raw = unformatPhoneNumber(phoneNum);
  const withZero = raw.startsWith('0') ? raw : `0${raw}`;
  try {
    return `+${getCountryCallingCode(countryCode)}${withZero.slice(1)}`;
  } catch (error) {
    return phoneNum;
  }
};

// NOTE: Made for numbers formatted by libphonenumber
// The dial code from the library includes the areacode so we rely on the formatting
export const removeDialCode = (phoneNum, countryCode, isMobile) => {
  if (!phoneNum || !countryCode) {
    return '';
  }
  try {
    const result = phoneNum.replace(
      phoneNum.includes(' ')
        ? /\+(.*?)\s/g
        : `+${getCountryCallingCode(countryCode)}`,
      '',
    );
    return (result.startsWith('1') && !isMobile) || result.startsWith('+')
      ? result
      : `0${result}`;
  } catch (error) {
    return phoneNum;
  }
};

export const spaceOnlyFormatting = (phoneNum) => {
  const raw = phoneNum.replace(/([()])/g, '').replace('-', ' ');
  return raw.startsWith('0') ? raw : `0${raw}`;
};
