import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';

import styles from './ScrollableContent.css';

const ScrollableContent = ({ content }) => {
  const renderHtmlContent = (htmlContent) => (
    <div
      key='scrollable'
      className='content'
      dangerouslySetInnerHTML={{
        __html: sanitize(htmlContent || '', { ADD_ATTR: ['target'] }),
      }}
    />
  );

  return (
    <div id='ScrollableContent' className={styles.root}>
      <section id='compact' className={styles.compact}>
        {content && renderHtmlContent(content)}
      </section>
    </div>
  );
};

ScrollableContent.propTypes = {
  content: PropTypes.string,
};

export default ScrollableContent;
